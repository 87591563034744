<template>
  <a class="edoc-logo" href="https://docs.empathy.co">
    <EdocsLogoIcon class="edoc-logo__image" alt="Edocs logo" />
  </a>
</template>

<style lang="scss" scoped>
  .edoc-logo {
    display: block;
    outline: none;
    &:hover {
      text-decoration: none;
    }
    &__image {
      width: 100%;
      margin-bottom: 0;
      transform: scale(0.95);
    }
  }
</style>
<script lang="ts">
  import { defineComponent } from 'vue';
  import { EdocsLogoIcon } from '../icons';

  export default defineComponent({
    name: 'Logo',
    components: { EdocsLogoIcon }
  });
</script>
