<template>
  <a
    :href="elem.url"
    target="_blank"
    :class="['grid-elem', { 'grid-elem--boosted': isBoosted(elem) }]"
  >
    <div class="grid-elem__top-section">
      <div class="grid-elem__pill-wrapper">
        <Spark v-if="isBoosted(elem)" class="grid-elem__spark-icon" />
        <div class="grid-elem__pill" :style="`background-color: ${getResultColor({ data: elem })}`">
          {{ elem.root.split(' ')[0] }}
        </div>
      </div>
      <Arrow class="grid-elem__icon" />
    </div>
    <p class="grid-elem__title">{{ elem.name ? elem.name : elem.root }}</p>
  </a>
</template>

<script lang="ts" setup>
  import { getResultColor } from '../scripts/utils';
  import { config } from '../scripts/config';
  import { Spark } from './icons';
  import Arrow from './icons/arrow.vue';

  interface Props {
    elem: any;
  }

  defineProps<Props>();

  function isBoosted(d: any) {
    return d.score > config.HIGHLIGHTED_BREAKPOINT;
  }
</script>

<style lang="scss" scoped>
  .grid-elem {
    padding: rem(12px);
    border-radius: rem(24px);
    text-decoration: none;
    color: #243d48;
    height: fit-content;
    border: rem(1px) solid #d0d4d7;
    background-color: $neutral--0;
    overflow: hidden;

    &--boosted {
      border: rem(1px) solid #243d48;
    }

    &__spark-icon {
      ::v-deep {
        path {
          fill: #243d48;
        }
      }
    }

    &__top-section {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }

    &__pill-wrapper {
      display: flex;
      gap: rem(6px);
      align-items: center;
    }

    &__pill {
      border-radius: 9999px;
      text-transform: uppercase;
      width: fit-content;
      height: fit-content;
      font-size: $font-size--xs;
      font-weight: $font-weight--semibold;
      line-height: rem(14.63px);
      letter-spacing: $letter-spacing--s;
      padding: rem(4px) rem(6px);
    }

    &__title {
      font-size: $font-size--sm;
      font-weight: $font-weight--medium;
      line-height: rem(21px);
      text-align: left;
      margin-bottom: 0;
    }
  }
</style>
