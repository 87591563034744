<template>
  <div
    class="grid-elem"
    @click="() => clickedHolon(elem)"
    :style="`--background-color: ${getBackgroundColor({ data: elem }, true)}`"
  >
    <div class="grid-elem__top-section">
      <div class="grid-elem__pill" :style="`background-color: ${getResultColor({ data: elem })}`">
        {{ elem.root ? elem.root.split(' ')[0] : '' }}
      </div>
      <component
        :is="opened ? Minus : Plus"
        class="grid-elem__icon"
        @click.native.stop="
          () => {
            opened = !opened;
          }
        "
      />
    </div>
    <p class="grid-elem__title">{{ elem.label }}</p>
    <CollapseHeight>
      <ul class="grid-elem__children" v-if="opened">
        <li class="grid-elem__child" v-if="child.label" v-for="child of elem.children">
          {{ child.label }}
        </li>
      </ul>
    </CollapseHeight>
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref, computed } from 'vue';
  import { getBackgroundColor, resultColor } from '../../scripts/utils';
  import { CollapseHeight, use$x } from '@empathyco/x-components';
  import { Spark } from '../icons';
  import Arrow from '@/components/icons/arrow.vue';
  import Plus from '@/components/icons/plus.vue';
  import { SimpleFilter } from '@empathyco/x-types';
  import Minus from '@/components/icons/minus.vue';
  export default defineComponent({
    name: 'HolonCard',
    components: {
      Minus,
      Plus,
      Arrow,
      Spark,
      CollapseHeight
    },
    props: {
      elem: {
        type: Object,
        required: true
      }
    },
    setup() {
      const opened = ref(false);
      const $x = use$x();

      const getResultColor = (elem: any): string => {
        return resultColor(elem);
      };

      const clickedHolon = (elem: SimpleFilter) => {
        $x.emit('UserClickedASimpleFilter', elem);
        $x.emit('UserClickedAFilter', elem);
        $x.emit('UserSetActiveFilter', elem);
      };

      return {
        opened,
        getResultColor,
        clickedHolon,
        getBackgroundColor,
        Plus,
        Minus
      };
    }
  });
</script>

<style lang="scss" scoped>
  .grid-elem {
    padding: 12px;
    border-radius: 24px;
    background-color: var(--background-color);
    border: 1px solid var(--background-color);
    text-decoration: none;
    color: #243d48;
    height: fit-content;

    &__top-section {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }

    &__pill {
      border-radius: 9999px;
      text-transform: uppercase;
      width: fit-content;
      height: fit-content;
      font-size: 8px;
      font-weight: 600;
      line-height: 9.75px;
      letter-spacing: 0.6px;
      padding: 4px 6px;
    }

    &__title {
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      text-align: left;
      margin-bottom: 0;
    }

    &__children {
      list-style-type: none;
      padding-left: 20px;
      margin: 0;
    }
    &__child {
      &:first-child {
        margin-top: 10px;
      }
      position: relative;
      text-align: left;
      margin-bottom: 10px;
      font-size: 12px;
      &::before {
        position: absolute;
        left: -15px;
        content: '•';
      }
    }
  }
</style>
