<template>
  <div id="holons-app" class="x">
    <symbol id="anchor">
      <DocumentIcon />
    </symbol>
    <symbol id="spark">
      <Spark />
    </symbol>
    <SnippetConfigExtraParams />
    <Tagging />
    <UrlHandler activeFilters="breadCrumbs" />
    <ExtraParams :values="breadCrumbs" />
    <MainModal :animation="animation">
      <Main />
    </MainModal>
  </div>
</template>

<script lang="ts">
  import { Component, Vue } from 'vue-property-decorator';
  import { UrlHandler } from '@empathyco/x-components/url';
  import { ExtraParams, SnippetConfigExtraParams } from '@empathyco/x-components/extra-params';
  import Main from './components/Main.vue';
  import '@empathyco/x-components/design-system/deprecated-full-theme.css';
  import { animateClipPath, MainModal, XOn } from '@empathyco/x-components';
  import { DocumentIcon, Spark } from './components/icons';
  import { State } from '@empathyco/x-components';
  import { Filter } from '@empathyco/x-types';
  import { Tagging } from '@empathyco/x-components/tagging';

  @Component({
    components: {
      DocumentIcon,
      Spark,
      UrlHandler,
      SnippetConfigExtraParams,
      Main,
      MainModal,
      ExtraParams,
      Tagging
    }
  })
  export default class App extends Vue {
    /**
     * Animation to use for opening/closing the modal.
     *
     * @internal
     */
    protected animation = animateClipPath();

    @State('facets', 'activeFilter')
    public activeFilters!: Filter[];

    get breadCrumbs() {
      return {
        activeFilters: this.activeFilters.map(filter => filter.id).join('-')
      };
    }
  }
</script>

<style lang="scss" scoped>
  .x {
    width: 100vw;
    height: 100vh;
    text-align: center;

    &::v-deep {
      .x-modal {
        z-index: 10 !important;
        .x-modal__content {
          height: 100%;
          width: 100%;
          background: white;
        }
      }
    }
  }
</style>
