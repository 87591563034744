<template>
  <FixedHeaderAndAsidesLayout ref="mainScroll">
    <template #header>
      <Header class="header" ref="header" />
    </template>
    <template #main>
      <div class="body">
        <GenAI />
        <div class="holons-response">
          <Breadcrumbs class="breadcrumbs" :style="`--headerHeight: ${headerHeight}px`" />
          <Fade>
            <Legend class="legend" v-if="$x.query.searchBox && !scrolled" />
          </Fade>
          <Holon ref="holon" class="holon" />
          <MobileGrid class="grid" />
        </div>
      </div>
    </template>
  </FixedHeaderAndAsidesLayout>
</template>

<script lang="ts">
  import { defineComponent, onBeforeUnmount, onMounted, ref } from 'vue';
  import { FixedHeaderAndAsidesLayout, Fade } from '@empathyco/x-components';
  import Results from './Results.vue';
  import Holon from './Holon.vue';
  import Legend from '@/components/Legend.vue';
  import Header from '@/components/header/Header.vue';
  import MobileGrid from '@/components/mobile/MobileGrid.vue';
  import GenAI from '@/components/GenAI.vue';
  import Breadcrumbs from '@/components/Breadcrumbs.vue';

  export default defineComponent({
    name: 'Main',
    components: {
      GenAI,
      MobileGrid,
      Header,
      Legend,
      FixedHeaderAndAsidesLayout,
      Results,
      Fade,
      Holon,
      Breadcrumbs
    },
    setup() {
      const scrolled = ref(false);
      const headerHeight = ref(0);
      const mainScroll = ref<HTMLElement | null>(null);
      const header = ref<HTMLElement | null>(null);

      const watchScroll = () => {
        const currentScroll: number = Math.abs(mainScroll.value?.scrollTop ?? 0);
        scrolled.value = currentScroll > 80;
        headerHeight.value = header.value?.getBoundingClientRect().height ?? 0;
      };

      onMounted(() => {
        mainScroll.value?.addEventListener('scroll', watchScroll);
        headerHeight.value = header.value?.getBoundingClientRect().height ?? 0;
      });

      onBeforeUnmount(() => {
        mainScroll.value?.removeEventListener('scroll', watchScroll);
      });

      return {
        scrolled,
        headerHeight
      };
    }
  });
</script>

<style lang="scss" scoped>
  .holons-response {
    position: relative;
  }

  .breadcrumbs {
    position: sticky;
    top: var(--headerHeight);
    z-index: 2;
  }

  .grid {
    display: none;
  }

  @media screen and (max-width: 1023px) {
    .legend {
      display: none;
    }
    .holon {
      display: none;
    }
    .grid {
      display: flex;
    }
  }
</style>
