<template>
  <svg width="42" height="40" viewBox="0 0 42 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20" r="20" transform="rotate(-90 20 20)" fill="#F5F6F7" />
    <path
      d="M16.6797 26.7824C16.6797 26.0683 17.3852 25.5684 18.0589 25.8051L18.9585 26.1212C19.3257 26.2502 19.726 26.2502 20.0932 26.1212L20.9928 25.8051C21.6665 25.5684 22.372 26.0683 22.372 26.7824C22.372 27.1197 22.2077 27.4359 21.9317 27.6299L21.1622 28.1706C20.1804 28.8605 18.8713 28.8605 17.8895 28.1706L17.12 27.6299C16.844 27.4359 16.6797 27.1197 16.6797 26.7824Z"
      fill="#919EA3"
    />
    <rect x="22.2178" y="18.7819" width="3.38462" height="3.38462" rx="1.69231" fill="#919EA3" />
    <rect x="13.9521" y="18.7819" width="3.38462" height="3.38462" rx="1.69231" fill="#919EA3" />
    <circle cx="19.6797" cy="20.3205" r="11.8462" stroke="#919EA3" stroke-width="1.69231" />
    <path
      d="M36.1489 14.1653C36.3245 14.1718 36.4972 14.119 36.6391 14.0154C36.7811 13.9117 36.884 13.7634 36.9313 13.5941C37.1605 12.5882 37.6691 11.6675 38.3986 10.938C39.1281 10.2085 40.0488 9.6999 41.0547 9.47071C41.2173 9.42331 41.36 9.32447 41.4616 9.18903C41.5632 9.0536 41.6181 8.88887 41.6181 8.71957C41.6181 8.55028 41.5632 8.38555 41.4616 8.25012C41.36 8.11468 41.2173 8.01584 41.0547 7.96844C40.0383 7.7398 39.109 7.22357 38.3779 6.48134C37.6468 5.73911 37.1446 4.80222 36.9313 3.78243C36.9313 3.57492 36.8489 3.3759 36.7021 3.22917C36.5554 3.08243 36.3564 3 36.1489 3C35.9414 3 35.7424 3.08243 35.5956 3.22917C35.4489 3.3759 35.3665 3.57492 35.3665 3.78243C35.1373 4.78832 34.6286 5.70903 33.8991 6.43853C33.1696 7.16803 32.2489 7.67666 31.243 7.90585C31.0805 7.95325 30.9378 8.05208 30.8362 8.18752C30.7346 8.32296 30.6797 8.48769 30.6797 8.65698C30.6797 8.82628 30.7346 8.991 30.8362 9.12644C30.9378 9.26188 31.0805 9.36071 31.243 9.40811C32.257 9.64206 33.1833 10.16 33.9136 10.9014C34.6439 11.6427 35.1478 12.5767 35.3665 13.5941C35.4138 13.7634 35.5167 13.9117 35.6586 14.0154C35.8006 14.119 35.9733 14.1718 36.1489 14.1653ZM33.5356 8.68828C34.639 8.08542 35.546 7.17836 36.1489 6.07496C36.7517 7.17836 37.6588 8.08542 38.7622 8.68828C37.6588 9.29114 36.7517 10.1982 36.1489 11.3016C35.546 10.1982 34.639 9.29114 33.5356 8.68828Z"
      fill="#919EA3"
    />
    <path
      d="M29.6816 6.78517L29.6818 6.78522L29.6834 6.77797C29.7817 6.35562 29.9998 5.96864 30.313 5.66176C30.6263 5.35484 31.0221 5.14055 31.4549 5.04395L31.4549 5.0442L31.4634 5.04178C31.5683 5.01181 31.661 4.94916 31.7273 4.8626L31.6082 4.77142L31.7273 4.8626C31.7936 4.77599 31.8297 4.67024 31.8297 4.56119C31.8297 4.45215 31.7936 4.3464 31.7273 4.25979C31.661 4.17323 31.5683 4.11058 31.4634 4.08061L31.4635 4.08034L31.4545 4.07835C31.0174 3.98204 30.6182 3.76466 30.3044 3.45257C29.994 3.14387 29.78 2.75544 29.687 2.33287C29.6825 2.20556 29.6289 2.08497 29.5376 1.99547C29.4421 1.90198 29.3133 1.85 29.1797 1.85C29.046 1.85 28.9172 1.90198 28.8218 1.99547C28.7308 2.08461 28.6773 2.2046 28.6724 2.33135C28.5725 2.74787 28.3559 3.12934 28.0463 3.4326C27.733 3.73952 27.3373 3.95381 26.9045 4.05041L26.9045 4.05016L26.896 4.05258C26.7911 4.08255 26.6984 4.14521 26.6321 4.23176C26.5658 4.31837 26.5297 4.42412 26.5297 4.53317C26.5297 4.64221 26.5658 4.74796 26.6321 4.83457C26.6984 4.92113 26.7911 4.98378 26.896 5.01375L26.8959 5.01397L26.9041 5.01583C27.3404 5.11443 27.7385 5.33266 28.0522 5.64455C28.3657 5.95641 28.5818 6.34897 28.6755 6.77613L28.6752 6.7762L28.6778 6.78517C28.7089 6.89399 28.7762 6.98876 28.8683 7.05463C28.9589 7.11941 29.0684 7.15269 29.1797 7.14983C29.291 7.15269 29.4005 7.11941 29.491 7.05463C29.5831 6.98876 29.6505 6.89399 29.6816 6.78517ZM29.1797 5.43045C28.9442 5.07895 28.6381 4.77842 28.2803 4.54718C28.6381 4.31594 28.9442 4.01541 29.1797 3.66391C29.4152 4.01541 29.7213 4.31594 30.0791 4.54718C29.7213 4.77842 29.4152 5.07895 29.1797 5.43045Z"
      fill="#919EA3"
      stroke="#919EA3"
      stroke-width="0.3"
    />
  </svg>
</template>

<script lang="ts">
  export default {};
</script>
