<template>
  <MainScrollItem :item="result" class="x-result">
    <BaseResultLink :result="result" class="x-result__description x-text">
      <p>{{ result.name }}</p>
    </BaseResultLink>
  </MainScrollItem>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';

  import { MainScrollItem } from '@empathyco/x-components/scroll';
  import { Result as ResultModel } from '@empathyco/x-types';
  import { BaseResultLink } from '@empathyco/x-components';

  export default defineComponent({
    name: 'Result',
    components: {
      MainScrollItem,
      BaseResultLink
    },
    props: {
      result: Object as PropType<ResultModel>
    }
  });
</script>

<style></style>
