<template>
  <ul class="breadcrumbs">
    <li v-if="activeFilter.length > 0">
      <BaseEventButton class="breadcrumbs__filter" :events="{ UserDeselectedAllHolons: undefined }">
        <BackIcon class="breadcrumbs__back-icon" />
      </BaseEventButton>
    </li>
    <li v-for="filter in activeFilter">
      <BaseEventButton
        :class="[
          'breadcrumbs__filter',
          'breadcrumbs__filter--current',
          {
            'breadcrumbs__filter--inactive': filter != activeFilter[activeFilter.length - 1]
          }
        ]"
        :style="`--filter-color: ${getColor(filter)}`"
        :events="{ UserDeselectedHolon: filter }"
      >
        <span class="breadcrumbs__filter-text">{{ filter.label }}</span>
        <BackIcon
          class="breadcrumbs__back-icon breadcrumbs__back-icon--filter"
          v-if="filter != activeFilter[activeFilter.length - 1]"
        />
      </BaseEventButton>
    </li>
  </ul>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import { BaseEventButton, useState, VueCSSClasses } from '@empathyco/x-components';
  import { HierarchicalFilter } from '@empathyco/x-types';
  import { sectionColor } from '../scripts/config';
  import { BackIcon } from './icons';

  export default defineComponent({
    name: 'Breadcrumbs',
    components: {
      BackIcon,
      BaseEventButton
    },
    setup() {
      const { activeFilter } = useState('facets', ['activeFilter']);

      const getColor = (filter: HierarchicalFilter): VueCSSClasses =>
        sectionColor(filter.root).base;

      return {
        activeFilter,
        getColor
      };
    }
  });
</script>

<style lang="scss" scoped>
  .breadcrumbs {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    padding-left: 0;
    margin-top: 0;
    gap: 8px;
    margin-left: 300px;
    margin-right: 45px;

    &__back-icon {
      width: 12px;
      &--filter {
        margin-top: -2px;
        margin-left: 5px;
      }
    }

    &__filter {
      display: flex;
      align-items: center;
      color: #243d48;
      border-radius: 22px;
      border: solid #243d48 1px;
      background-color: white;
      padding: 8px 10px;
      margin-bottom: 8px;
      min-height: 30px;
      text-transform: uppercase;
      font-family: 'Montserrat', sans-serif;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 1px;
      font-weight: 500;
      cursor: pointer;

      &--current {
        border-color: transparent;
        background-color: var(--filter-color);
      }
      &--inactive {
        background-color: white;
        border-color: var(--filter-color);
      }
    }

    &__filter-text {
      max-width: 140px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  @media screen and (max-width: 1023px) {
    .breadcrumbs {
      padding-left: 20px;
      padding-right: 20px;
      margin-left: -20px;
      margin-right: -50px;
      flex-wrap: nowrap;
      max-width: calc(100vw - 40px);
      overflow: auto;
      margin-top: 16px;
    }
  }
</style>
