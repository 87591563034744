<template>
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>Combined Shape</title>
    <g
      id="Symbols"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path
        d="M2,18 L18,2 M18,18 L2,2"
        id="Combined-Shape"
        stroke="#FFF"
        stroke-width="2.51415744"
      ></path>
    </g>
  </svg>
</template>

<script lang="ts">
  export default {};
</script>
