<template>
  <div class="no-results">
    <p class="no-results__small-text">The query doesn’t have a contextual match.<br/>Maybe you’re interested in:</p>
    <div class="no-results__grid">
      <div class="no-results__column" v-for="column in suggestions">
        <ResultCard v-if="column.root" :elem="column" class="no-results__elem" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import noResultsSuggestions from '../data/no-results-suggestions.json';
  import ResultCard from './ResultCard.vue';

  const suggestions = noResultsSuggestions;
</script>

<style lang="scss" scoped>
  .no-results {
    $class-component: &;
    top: 64px;
    left: 0;
    width: 100%;
    height: 100%;

    &__small-text {
      text-align: left;
      font-size: $font-size--sm;
      line-height: rem(21px);
      font-weight: $font-weight--regular;
      color: #243d48;
      margin-top: 0;
      padding-left: rem(12px);
    }

    &__grid {
      display: flex;
      flex-wrap: wrap;
      padding-right: rem(12px);
      padding-left: rem(12px);
      gap: rem(12px);
    }

    &__column {
      display: flex;
    }

    &__elem {
      width: rem(170px);
      height: rem(86px);
    }
  }
</style>
