<template>
  <div class="legend">
    <div class="legend__box">
      <p class="legend__title">COLOR LEGEND</p>
      <ul class="legend__content">
        <li class="legend__content-item" :style="`--item-color:${getLegendColor('UNDERSTAND')}`">
          UNDERSTAND EMPATHY PLATFORM
        </li>
        <li class="legend__content-item" :style="`--item-color:${getLegendColor('ONBOARD')}`">
          ONBOARD EMPATHY PLATFORM
        </li>
        <li class="legend__content-item" :style="`--item-color:${getLegendColor('PLAY')}`">
          PLAY WITH EMPATHY PLATFORM
        </li>
        <li class="legend__content-item" :style="`--item-color:${getLegendColor('DEVELOP')}`">
          DEVELOP EMPATHY PLATFORM
        </li>
        <li class="legend__content-item" :style="`--item-color:${getLegendColor('HOME')}`">
          EPDOCS TOOLS
        </li>
      </ul>
    </div>
    <div class="legend__recommended">
      <RecommendedIcon class="legend__recommended-icon" alt="Recommended item" />
      <p class="legend__recommended-text">RECOMMENDED CONTENT</p>
    </div>
  </div>
</template>

<script lang="ts">
  import { RecommendedIcon } from './icons';
  import { defineComponent } from 'vue';
  import { VueCSSClasses } from '@empathyco/x-components';
  import config, { sectionColor } from '../scripts/config';

  export default defineComponent({
    name: 'Legend',
    components: {
      RecommendedIcon
    },
    setup() {
      const getLegendColor = (
        element: 'UNDERSTAND' | 'ONBOARD' | 'PLAY' | 'DEVELOP' | 'HOME'
      ): VueCSSClasses => sectionColor(config.sections[element]).base;

      return {
        getLegendColor
      };
    }
  });
</script>

<style lang="scss" scoped>
  .legend {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 1;
    padding: 1px 20px 4px 3px;
    margin-left: 44px;
    pointer-events: none;
    gap: 8px;

    &__box {
      display: flex;
      flex-direction: column;
      z-index: 1;
      box-sizing: border-box;
      width: 210px;
      height: auto;
      backdrop-filter: blur(12px);
      border-radius: 8px;
      border: 1px solid #e3e6e7;
      backdrop-filter: blur(12px);
      padding: 20px 20px 4px 16px;
      pointer-events: none;
    }

    &__recommended {
      z-index: 1;
      pointer-events: none;
      display: flex;
      font-family: 'Montserrat';
      list-style-type: none;
      text-align: left;
      margin-left: 13px;
    }

    &__recommended-icon {
      padding-top: 9px;
    }

    &__recommended-text {
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      color: #66777f;
      padding-left: 8px;
    }

    &__title {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      text-align: left;
      padding-bottom: 18px;
      padding-left: 2px;
      margin: 0;
      color: #66777f;
    }

    &__content {
      display: flex;
      font-family: 'Montserrat';
      flex-direction: column;
      align-items: flex-start;
      list-style-type: none;
      margin-top: 0;
      pointer-events: none;
      text-align: left;
      padding-left: 0;
      gap: 16px;
    }

    &__content-item {
      color: black;
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      color: #66777f;
      padding-left: 24px;

      &::before {
        content: '●';
        font-size: 20px;
        padding-right: 9px;
        padding-top: 2px;
        margin-left: -24px;
        color: var(--item-color);
      }
    }
  }
</style>
