<template>
  <FixedHeaderAndAsidesLayout>
    <template #header>
      <Header class="main__header" ref="header" :hundleSearch="searchInactive && !$x.query.searchBox" />
    </template>
    <template #main>
      <div class="main__body-wrapper">
        <div class="main__body" :style="`--headerHeight: ${headerHeight}px`">
          <div
            v-if="!searchInactive"
            class="main__wrapper"
          >
            <div :class="['main__results', { 'main__results--expanded': drawerMoved }]">
              <p v-if="!isMobile" class="main__small-text" ref="smallText">Find a document</p>
              <Breadcrumbs
                v-if="!$x.noResults"
                class="main__breadcrumbs"
              />
              <ResultGrid class="main__grid" :isDrawerExpanded="drawerMoved" />
            </div>
          </div>
          <button
            v-if="!searchInactive"
            :class="['main__drawer-button', { 'main__drawer-button--expanded': drawerMoved }]"
            @click="() => (drawerMoved = !drawerMoved)"
          >
            <DropdownArrow class="main__drawer-icon" />
          </button>
          <GenAI class="main__genai" @hundle-search="value => searchInactive=value" />
        </div>
      </div>
    </template>
  </FixedHeaderAndAsidesLayout>
</template>

<script lang="ts" setup>
  import { onMounted, ref } from 'vue';
  import { useElementSize, watchDebounced } from '@vueuse/core';
  import { breakpoints } from '../scripts/config';
  import { FixedHeaderAndAsidesLayout, XPlugin } from '@empathyco/x-components';
  import Header from './header/Header.vue';
  import ResultGrid from './ResultGrid.vue';
  import GenAI from './gen-ai/GenAI.vue';
  import Breadcrumbs from './Breadcrumbs.vue';
  import { searchXModule } from '@empathyco/x-components/search';
  import DropdownArrow from './icons/dropdown-arrow.vue';
  import { use$x } from '@empathyco/x-components';

  const smallText = ref<HTMLElement | null>(null);
  const header = ref<HTMLElement | null>(null);
  const searchInactive = ref(true);
  const drawerMoved = ref(false);
  const $x = use$x();
  const isMobile = breakpoints.smallerOrEqual('md');
  const headerHeight = ref(0);
  const { height: headerContent } = useElementSize(header);

  watchDebounced(
    headerContent,
    () => {
      headerHeight.value = headerContent.value;
    },
    { debounce: 125, maxWait: 125 }
  );

  onMounted(() => {
    XPlugin.registerXModule(searchXModule);
  });
</script>

<style lang="scss" scoped>
  .main {
    $class-component: &;

    &__small-text {
      text-align: left;
      font-size: $font-size--md;
      line-height: rem(20px);
      font-weight: $font-weight--bold;
      color: #243d48;
      margin: 0;
      padding-left: rem(12px);
    }

    &__body-wrapper {
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
    &__body {
      position: relative;
      display: flex;
      width: fit-content;
      height: calc(100dvh - var(--headerHeight));
      box-sizing: border-box;
      border-top: 1px solid $neutral--25;
    }

    &__wrapper {
      display: flex;
      align-items: end;
      max-height: calc(100dvh - 168px);
      position: sticky;
    }

    &__results {
      display: flex;
      flex-direction: column;
      min-width: 30vw;
      max-width: 30vw;
      background-color: $neutral--10;
      padding: rem(40px) rem(64px) rem(40px) max(rem(64px), calc((100vw - 1440px) / 2));
      transition-property: min-width, max-width;
      transition-duration: 0.5s;
      box-sizing: border-box;
      height: 100%;
      overflow-y: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      gap: rem(16px);

      &::-webkit-scrollbar {
        display: none;
      }

      &--expanded {
        min-width: 70vw;
        max-width: 70vw;
      }
    }

    &__drawer-button {
      position: absolute;
      top: rem(20px);
      left: calc(30vw - #{rem(23px)});
      width: rem(46px);
      height: rem(46px);
      background-color: $neutral--10;
      border: solid 7px $white;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      padding-bottom: rem(3px);
      transform: rotate(90deg);
      transform-origin: 50% 50%;
      transition-property: left, rotate;
      transition-duration: 0.5s;
      z-index: 1;

      &--expanded {
        left: calc(70vw - #{rem(23px)});
        transform: rotate(-90deg);
      }
    }

    &__drawer-icon {
      width: rem(24px);
      height: rem(24px);
    }

    &__genai {
      min-width: 70vw;
      max-width: 70vw;
      padding: rem(20px) max(rem(64px), calc((100vw - 1440px) / 2)) rem(20px) rem(44px);
      box-sizing: border-box;
      max-height: calc(100dvh - 168px);
      overflow-y: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &__breadcrumbs {
      margin: 0;
    }

    @media screen and (max-width: $breakpoint__tablet--max) {
      &__small-text {
        padding: 0;
      }
      &__genai {
        max-height: calc(100dvh - 70.5px);
        min-width: 66vw;
        max-width: 66vw;
      }
      &__wrapper {
        max-height: calc(100dvh - 70.5px);
      }
      &__results {
        min-width: 34vw;
        max-width: 34vw;
        &--expanded {
          min-width: 66vw;
          max-width: 66vw;
        }
      }
      &__drawer-button {
        left: calc(34vw - #{rem(23px)});
        &--expanded {
          left: calc(66vw - #{rem(23px)});
        }
      }
    }
    @media screen and (max-width: $breakpoint__tablet--min) {
      &__body {
        width: 100%;
        display: grid;
        grid-template-areas:
          'genai'
          'holons';
        grid-auto-rows: min-content;
        overflow-y: auto; 
        border-top: none;
      }
      &__drawer-button {
        display: none;
      }
      &__wrapper {
        width: 100%;
        max-height: unset;
      }
      &__wrapper {
        grid-area: holons;
        overflow-x: hidden;
      }
      &__results {
        max-width: none;
        min-width: unset;
        width: 100%;
        background-color: $white;
        padding: rem(40px) rem(20px) rem(10px) rem(20px);
        margin: 0;
        overflow: hidden;
      }
      &__genai {
        min-width: unset;
        max-width: none;
        width: 100%;
        max-height: none;
        box-sizing: unset;
        grid-area: genai;
        padding: 0;
        margin: 0;
        overflow: hidden;
      }
    }
  }
</style>
