<template>
  <svg
    width="197"
    height="192"
    viewBox="0 0 197 192"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="79.6785" cy="38.2857" r="37.7143" fill="#FFFAEF" />
    <ellipse cx="95" cy="127.857" rx="23.5714" ry="23.5714" fill="#FFFAEF" />
    <circle cx="29.5892" cy="127.268" r="28.875" fill="#FFFAEF" />
    <circle cx="66.125" cy="174.411" r="13.5536" fill="#FFFAEF" />
    <ellipse cx="163.946" cy="159.089" rx="32.4107" ry="32.4107" fill="#FFFAEF" />
  </svg>
</template>

<script lang="ts">
  export default {};
</script>
