<template>
  <svg width="12" height="10" viewBox="0 1 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.1389 2.763H2.21081L2.71059 2.02032C2.77725 1.92208 2.81588 1.80753 2.82234 1.68897C2.8288 1.57042 2.80283 1.45235 2.74723 1.34745C2.69164 1.24254 2.60851 1.15477 2.50678 1.09355C2.40505 1.03234 2.28856 0.999994 2.16984 1H2.16847C2.06168 0.999993 1.95625 1.02401 1.85999 1.07027C1.76374 1.11653 1.67912 1.18385 1.61241 1.26724L0.0747411 3.18962C0.0263588 3.2501 0 3.32524 0 3.40269C0 3.48014 0.0263588 3.55529 0.0747411 3.61577L1.61258 5.53801C1.67929 5.62142 1.7639 5.68875 1.86016 5.73501C1.95641 5.78128 2.06184 5.8053 2.16864 5.8053H2.16984C2.28856 5.8053 2.40503 5.77296 2.50676 5.71174C2.60848 5.65053 2.6916 5.56276 2.7472 5.45786C2.8028 5.35297 2.82876 5.2349 2.82231 5.11636C2.81586 4.99781 2.77724 4.88327 2.71059 4.78502L2.21068 4.04209H7.1389C7.89969 4.04209 8.62932 4.34431 9.16728 4.88227C9.70524 5.42022 10.0075 6.14985 10.0075 6.91064C10.0075 7.67143 9.70524 8.40106 9.16728 8.93902C8.62932 9.47698 7.89969 9.7792 7.1389 9.7792H5.74406C5.57445 9.7792 5.41178 9.84658 5.29184 9.96651C5.1719 10.0865 5.10452 10.2491 5.10452 10.4187C5.10452 10.5884 5.1719 10.751 5.29184 10.871C5.41178 10.9909 5.57445 11.0583 5.74406 11.0583H7.1389C8.23892 11.0583 9.29389 10.6213 10.0717 9.84346C10.8496 9.06563 11.2865 8.01066 11.2865 6.91064C11.2865 5.81062 10.8496 4.75565 10.0717 3.97782C9.29389 3.19998 8.23892 2.763 7.1389 2.763Z"
      fill="#243D48"
    />
  </svg>
</template>

<script lang="ts">
  export default {};
</script>
