<template>
  <div class="x-input-group x-margin--top-00 x-input-group--card x-border-radius--20 search-bar">
    <SearchInput
      class="search-bar__input"
      :instant="false"
      tabindex="1"
      :placeholder="placeholder"
      :maxLength="120"
    />
    <ClearSearchInput v-if="$x.query.searchBox" class="x-input-group-button search-bar__clear">
      Clear
    </ClearSearchInput>
    <SearchIcon v-else class="search-bar__icon" />
  </div>
</template>

<script setup lang="ts">
  import { onMounted, ref } from 'vue';
  import { SearchInput, ClearSearchInput } from '@empathyco/x-components/search-box';
  import { SearchIcon } from '../icons';

  const originalPlaceholder = 'Love AI. Love Privacy. Type away!';
  const placeholder = ref('');

  const fillPlaceholder = (): void => {
    const characters = originalPlaceholder.split('');
    characters.forEach((character, index) => {
      setTimeout(() => {
        placeholder.value += character;
      }, 75 * index);
    });
  };

  onMounted(() => {
    fillPlaceholder();
  });
</script>

<style lang="scss" scoped>
  .search-bar {
    $base-class: &;
    border: solid 1px $color-light-blue !important;
    height: rem(44px);
    padding-left: rem(22px);
    padding-right: rem(17px);

    #{$base-class}__input {
      font-size: $font-size--md;

      &:focus {
        outline: none;
      }
      &::placeholder {
        font-size: $font-size--md;
        font-weight: $font-weight--regular;
        color: $neutral--90;
      }
    }

    #{$base-class}__clear {
      font-size: $font-size--md;
      color: $color-light-blue;
      padding: 0;
    }

    &__icon::v-deep {
      path {
        fill: $neutral--75;
      }
    }

    @media screen and (max-width: 767px) {
      padding-left: rem(12px);
      padding-right: rem(8px);
      gap: 0;

      &__input::placeholder {
        font-size: $font-size--sm;
      }

      &__icon {
        width: rem(18px);
      }
    }

    @media screen and (max-width: 374px) {
      width: rem(230px);
      &__input::placeholder {
        font-size: rem(11px);
      }
    }
  }
</style>
