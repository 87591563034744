<template>
  <svg width="24" height="24" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M26.4037 12.8546H23.0604C22.5964 12.8546 22.137 12.7632 21.7084 12.5857C21.2797 12.4081 20.8902 12.1479 20.5622 11.8198C20.2341 11.4917 19.9738 11.1022 19.7963 10.6736C19.6187 10.2449 19.5273 9.78552 19.5273 9.32156V6.0039C19.5273 5.75267 19.6271 5.51173 19.8048 5.33409C19.9824 5.15644 20.2234 5.05664 20.4746 5.05664C20.7258 5.05664 20.9668 5.15644 21.1444 5.33409C21.3221 5.51173 21.4219 5.75267 21.4219 6.0039V9.32156C21.4222 9.75602 21.5949 10.1726 21.9022 10.4798C22.2094 10.787 22.6259 10.9597 23.0604 10.9601H26.4037C26.6549 10.9601 26.8959 11.0599 27.0735 11.2375C27.2511 11.4152 27.3509 11.6561 27.3509 11.9073C27.3509 12.1586 27.2511 12.3995 27.0735 12.5772C26.8959 12.7548 26.6549 12.8546 26.4037 12.8546Z"
      fill="#243D48"
    />
    <path
      d="M23.8178 27.7974H13.3368C13.0856 27.7974 12.8446 27.6976 12.667 27.52C12.4893 27.3423 12.3895 27.1014 12.3895 26.8502C12.3895 26.5989 12.4893 26.358 12.667 26.1804C12.8446 26.0027 13.0856 25.9029 13.3368 25.9029H23.8178C24.2523 25.9026 24.6689 25.7299 24.9762 25.4227C25.2834 25.1155 25.4562 24.6989 25.4565 24.2644V12.2145C25.4565 12.1906 25.4477 12.1674 25.4317 12.1496L20.078 6.12865C20.0688 6.11833 20.0575 6.11006 20.0449 6.10441C20.0322 6.09876 20.0186 6.09584 20.0047 6.09586H10.1903C9.75585 6.0962 9.3393 6.26891 9.0321 6.57609C8.72489 6.88326 8.55213 7.29979 8.55175 7.73423V17.7923C8.55175 18.0435 8.45195 18.2844 8.27431 18.4621C8.09666 18.6397 7.85572 18.7395 7.60449 18.7395C7.35326 18.7395 7.11232 18.6397 6.93467 18.4621C6.75703 18.2844 6.65723 18.0435 6.65723 17.7923V7.73423C6.65723 6.7972 7.02946 5.89856 7.69203 5.23598C8.35461 4.5734 9.25326 4.20117 10.1903 4.20117H20.0047C20.2861 4.20128 20.5642 4.26092 20.8208 4.37616C21.0774 4.4914 21.3068 4.65964 21.4938 4.86983L26.8473 10.8908C27.1719 11.2553 27.3512 11.7264 27.351 12.2145V24.2644C27.351 24.7283 27.2597 25.1878 27.0821 25.6164C26.9045 26.0451 26.6443 26.4345 26.3162 26.7626C25.9882 27.0907 25.5987 27.3509 25.17 27.5285C24.7414 27.706 24.2818 27.7974 23.8178 27.7974Z"
      fill="#243D48"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.95238 20.5723C4.4264 20.5723 4 20.9987 4 21.5246C4 22.0506 4.4264 22.477 4.95238 22.477H7.36619C4.61603 23.8461 3.32739 27.0245 4.40901 30.1365C4.58169 30.6333 5.13542 30.933 5.64579 30.8058C6.15616 30.6786 6.42992 30.1727 6.25724 29.6759C5.38831 27.1759 6.61445 24.6829 8.95238 23.8968V26.0961C8.95238 26.6221 9.37878 27.0485 9.90476 27.0485C10.4307 27.0485 10.8571 26.6221 10.8571 26.0961V22.2866C10.8571 21.3398 10.0896 20.5723 9.14286 20.5723H4.95238Z"
      fill="#243D48"
    />
  </svg>
</template>

<script lang="ts">
  export default {};
</script>
