<template>
  <div v-if="mobile" class="foot-note foot-note--mobile">
    <p>{{ poweredByText }}</p>
    <p>
      {{ warningText }}
    </p>
  </div>
  <div v-else-if="$x.query.searchBox" class="foot-note foot-note--desktop">
    <p>{{ poweredByText }}</p>
    <div class="foot-note__warning-wrapper">
      <WarningIcon class="foot-note__warning-icon" />
      <div class="foot-note__warning">
        {{ warningText }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { WarningIcon } from '../icons';
  import { use$x } from '@empathyco/x-components';

  interface Props {
    mobile?: boolean;
  }
  withDefaults(defineProps<Props>(), {
    mobile: false
  });

  const poweredByText = 'Powered by Empathy AI [beta] & Mistral. A privacy-first experience.';
  const warningText =
    'Empathy AI has carefully been trained under the closed EPDocs ecosystem to provide accurate information. Since Empathy AI creates the responses autonomously, they may sometimes be imprecise.';
  const $x = use$x();
</script>

<style lang="scss">
  .foot-note {
    $class-component: &;
    box-sizing: border-box;
    font-style: italic;
    font-size: $font-size--sm;
    padding-left: rem(16px);
    padding-right: rem(16px);
    margin-bottom: rem(25px);
    margin-top: 0;
    width: 100%;
    text-align: left;
    color: #66777f;
    font-family: Lora;
    font-style: italic;
    line-height: rem(21px);

    p {
      margin: 0;
    }

    &--desktop {
      display: flex;
      gap: rem(8px);
    }
    &--mobile {
      margin: 0;
      padding-left: 0;
      display: none;
      flex-direction: column;
      gap: rem(12px);
      padding-top: rem(6px);
    }

    &__warning-wrapper {
      position: relative;
      &:hover {
        #{$class-component}__warning {
          display: unset;
        }
      }
    }

    &__warning {
      display: none;
      position: absolute;
      width: rem(300px);
      top: rem(32px);
      right: rem(-16px);
      background-color: #243d48;
      color: white;
      padding: rem(16px) rem(20px) rem(16px) rem(20px);
      border-radius: rem(8px);
      font-style: normal;
      z-index: 2;

      &::before {
        position: absolute;
        top: rem(-5px);
        right: rem(16px);
        content: '';
        width: rem(20px);
        height: rem(20px);
        transform: rotate(45deg);
        background-color: #243d48;
      }
    }

    &__warning-icon {
      &--mobile {
        display: none;
      }
    }

    @media screen and (max-width: $breakpoint__small-tablet--max) {
      &--desktop {
        display: none;
      }
      &--mobile {
        display: flex;
      }

      &__warning-icon {
        &--mobile {
          display: unset;
        }
      }
    }
  }
</style>
