<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.87305 2.5C9.48184 2.5 9.16471 2.81713 9.16471 3.20833V9.41667H3.20638C2.81518 9.41667 2.49805 9.7338 2.49805 10.125C2.49805 10.5162 2.81518 10.8333 3.20638 10.8333H9.16471V16.7917C9.16471 17.1829 9.48184 17.5 9.87305 17.5C10.2642 17.5 10.5814 17.1829 10.5814 16.7917V10.8333H16.7897C17.1809 10.8333 17.498 10.5162 17.498 10.125C17.498 9.7338 17.1809 9.41667 16.7897 9.41667H10.5814V3.20833C10.5814 2.81713 10.2642 2.5 9.87305 2.5Z"
      fill="#243D48"
    />
  </svg>
</template>

<script lang="ts">
  export default {};
</script>
