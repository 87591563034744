<template>
  <div id="holons-app" class="x">
    <SnippetConfigExtraParams />
    <Tagging />
    <UrlHandler activeFilters="breadCrumbs" />
    <ExtraParams :values="breadCrumbs" />
    <MainModal :animation="animation">
      <Main />
    </MainModal>
  </div>
</template>

<script lang="ts" setup>
  import { UrlHandler } from '@empathyco/x-components/url';
  import { ExtraParams, SnippetConfigExtraParams } from '@empathyco/x-components/extra-params';
  import Main from './components/Main.vue';
  import '@empathyco/x-components/design-system/deprecated-full-theme.css';
  import { animateClipPath, MainModal, useState } from '@empathyco/x-components';
  import { Tagging } from '@empathyco/x-components/tagging';
  import { computed } from 'vue';

  // Animation to use for opening/closing the modal.
  const animation = animateClipPath();
  const { activeFilter } = useState('facets', ['activeFilter']);

  const breadCrumbs = computed(() => {
    return {
      activeFilters: activeFilter.value.map((filter: any) => filter.id).join('-')
    };
  });
</script>

<style lang="scss" scoped>
  .x {
    width: 100vw;
    height: 100vh;
    text-align: center;
    &::v-deep {
      .x-modal {
        z-index: 10 !important;
        .x-layout__main {
          grid-column: page;
        }
        .x-modal__content {
          height: 100%;
          width: 100%;
          background: white;
        }
      }
    }
  }
</style>
