<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.4585 9.58329C3.4585 9.19209 3.77563 8.87496 4.16683 8.87496H16.6073C16.9985 8.87496 17.3156 9.19209 17.3156 9.58329C17.3156 9.97449 16.9985 10.2916 16.6073 10.2916H4.16683C3.77563 10.2916 3.4585 9.97449 3.4585 9.58329Z"
      fill="#243D48"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.1082 10.0842C16.8316 10.3608 16.3831 10.3608 16.1064 10.0842L11.5231 5.50083C11.2465 5.22421 11.2465 4.77571 11.5231 4.49909C11.7997 4.22247 12.2482 4.22247 12.5248 4.49909L17.1082 9.08243C17.3848 9.35905 17.3848 9.80754 17.1082 10.0842Z"
      fill="#243D48"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.5231 14.6675C11.7997 14.9441 12.2482 14.9441 12.5248 14.6675L17.1082 10.0842C17.3848 9.80754 17.3848 9.35905 17.1082 9.08243C16.8316 8.8058 16.3831 8.8058 16.1064 9.08243L11.5231 13.6658C11.2465 13.9424 11.2465 14.3909 11.5231 14.6675Z"
      fill="#243D48"
    />
  </svg>
</template>

<script lang="ts">
  export default {};
</script>
