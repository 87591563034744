const config = {
  HIGHLIGHTED_BREAKPOINT: 1000,
  WIDTH: 1000,
  HEIGHT: 1000,
  sections: {
    UNDERSTAND: 'Understand Empathy Platform',
    ONBOARD: 'Onboard Empathy Platform',
    PLAY: 'Play With Empathy Platform',
    DEVELOP: 'Develop Empathy Platform',
    HOME: 'Home'
  },
  colors: {
    PURPLE: {
      base: '#D1C1D3',
      weak: '#E8E0E9',
      dark: '#5B435F',
      shadow: 'rgb(53 16 58 / 30%)'
    },
    LIGHTBLUE: {
      base: '#98d5df',
      weak: '#cbeaef',
      dark: '#246772',
      shadow: 'rgb(0 128 148 / 30%)'
    },
    GREEN: {
      base: '#B3D9C7',
      weak: '#D9ECE3',
      dark: '#1D8753',
      shadow: 'rgb(29 135 83 / 30%)'
    },
    YELLOW: {
      base: '#FEE09D',
      weak: '#FFEFCE',
      dark: '#9E6E01',
      shadow: 'rgb(158 110 1 / 30%)'
    },
    ORANGE: {
      base: '#f0afa1',
      weak: '#f8d7d0',
      dark: '#8A2B16',
      shadow: 'rgb(218 61 28 / 30%)'
    },
    GREY: {
      base: '#E3E6E7',
      weak: '#FAFBFB',
      dark: '#1B2D36',
      shadow: 'rgb(36 61 72 / 30%)'
    },
    WHITE: '#FFF'
  }
};

export const sectionColor = (section: string) => {
  const sectionDictionary = {
    [config.sections.UNDERSTAND]: config.colors.PURPLE,
    [config.sections.ONBOARD]: config.colors.LIGHTBLUE,
    [config.sections.PLAY]: config.colors.GREEN,
    [config.sections.DEVELOP]: config.colors.YELLOW,
    [config.sections.HOME]: config.colors.ORANGE
  };

  return sectionDictionary[section] ? sectionDictionary[section] : config.colors.GREY;
};

export default config;
