<template>
  <div class="grid" ref="gridElem">
    <NoResults v-if="$x.noResults" />
    <div v-else class="grid__column" v-for="column in grid">
      <component
        v-if="elem.root"
        :is="elem.modelName === 'Result' ? ResultCard : HolonCard"
        :elem="elem"
        v-for="elem in column"
        class="grid__elem"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { ref } from 'vue';
  import { Facet, Result, Filter } from '@empathyco/x-types';
  import { createDataset } from '../scripts/paint';
  import ResultCard from './ResultCard.vue';
  import HolonCard from './HolonCard.vue';
  import NoResults from './NoResults.vue';
  import { use$x } from '@empathyco/x-components';
  import { breakpoints } from '../scripts/config';
  import { useElementSize, watchDebounced } from '@vueuse/core';

  interface Props {
    isDrawerExpanded: boolean;
  }

  const props = defineProps<Props>();

  const localFacets = ref<Facet[]>([]);
  const localResults = ref<Result[]>([]);
  const grid = ref<any[][]>([]);
  const activeFilter = ref<Filter[]>([]);
  const $x = use$x();
  const gridElem = ref<HTMLElement | null>(null);
  const { width: gridWidth } = useElementSize(gridElem);

  watchDebounced(
    gridWidth,
    () => {
      paint();
    },
    { debounce: 125, maxWait: 125 }
  );

  const reorder = (dataset: any[]): any[] => {
    const results = dataset.filter(elem => elem.modelName === 'Result').reverse();
    const holons = dataset.filter(elem => elem.modelName !== 'Result').reverse();
    const finalArray = [];

    let resultsTurn = true;

    while (results.length > 0 || holons.length > 0) {
      if (resultsTurn) {
        if (results.length >= 2) {
          finalArray.push(results.pop());
          finalArray.push(results.pop());
        } else if (results.length === 1) {
          finalArray.push(results.pop());
        }
      } else {
        if (holons.length >= 2) {
          finalArray.push(holons.pop());
          finalArray.push(holons.pop());
        } else if (holons.length === 1) {
          finalArray.push(holons.pop());
        }
      }

      resultsTurn = !resultsTurn;
    }

    return finalArray;
  };
  const toColumns = (elements: any[]): any[][] => {
    grid.value = [];
    const isMobile = breakpoints.isSmallerOrEqual('md');

    let COLUMN_COUNT = 1;

    if (isMobile) {
      if (breakpoints.isSmallerOrEqual('sm')) {
        COLUMN_COUNT = 1;
      } else {
        COLUMN_COUNT = 2;
      }
    } else {
      if (props.isDrawerExpanded) {
        if (gridWidth.value < 768) {
          COLUMN_COUNT = 2;
        } else if (gridWidth.value < 900) {
          COLUMN_COUNT = 3;
        } else if (gridWidth.value < 1024) {
          COLUMN_COUNT = 4;
        } else {
          COLUMN_COUNT = 5;
        }
      }
    }

    for (let i = 0; i < COLUMN_COUNT; i++) {
      grid.value.push([]);
    }

    elements.forEach((elem, index) => {
      const modulus = index % COLUMN_COUNT;
      grid.value[modulus].push(elem);
    });

    return grid.value;
  };
  const paint = (): void => {
    const dataset = createDataset(localResults.value, localFacets.value, activeFilter.value);
    const orderedDataset = reorder(dataset.children);
    grid.value = toColumns(orderedDataset);
  };
  // Custom event handling
  const updateHolons = (facets: Facet[]): void => {
    localFacets.value = facets;
    paint();
  };

  const updateResults = (results: Result[]): void => {
    localResults.value = results;
    paint();
  };

  $x.on('ResultsChanged', false).subscribe(updateResults);
  $x.on('FacetsChanged', false).subscribe(updateHolons);
</script>

<style lang="scss" scoped>
  .grid {
    display: flex;
    gap: rem(12px);
    margin-bottom: rem(20px);

    &__column {
      display: flex;
      flex-direction: column;
      flex: 1;
      gap: rem(12px);
      width: calc(50% + rem(6px));
    }
  }
</style>
