<template>
  <div class="grid">
    <NoResultsMobile v-if="$x.noResults"></NoResultsMobile>
    <div v-else class="grid__column" v-for="column in grid">
      <component
        v-if="elem.root"
        :is="elem.modelName === 'Result' ? ResultCard : HolonCard"
        :elem="elem"
        v-for="elem in column"
        class="grid__elem"
      />
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref, onMounted, onBeforeUnmount, getCurrentInstance } from 'vue';
  import { Facet, Result, Filter } from '@empathyco/x-types';
  import { createDataset } from '../../scripts/paint';
  import { getBackgroundColor } from '../../scripts/utils';
  import { Spark } from '../icons';
  import ResultCard from './ResultCard.vue';
  import HolonCard from './HolonCard.vue';
  import NoResultsMobile from './NoResultsMobile.vue';
  import * as d3 from "d3";
  import {use$x} from "@empathyco/x-components";

  export default defineComponent({
    name: 'MobileGrid',
    components: {
      Spark,
      NoResultsMobile,
      ResultCard,
      HolonCard
    },
    setup() {
      const localFacets = ref<Facet[]>([]);
      const localResults = ref<Result[]>([]);
      const grid = ref<any[][]>([]);
      const instance = getCurrentInstance();
      const activeFilter = ref<Filter[]>([]);
      const $x = use$x();

      const reorder = (dataset: any[]): any[] => {
        const results = dataset.filter(elem => elem.modelName === 'Result').reverse();
        const holons = dataset.filter(elem => elem.modelName !== 'Result').reverse();
        const finalArray = [];

        let resultsTurn = true;

        while (results.length > 0 || holons.length > 0) {
          if (resultsTurn) {
            if (results.length >= 2) {
              finalArray.push(results.pop());
              finalArray.push(results.pop());
            } else if (results.length === 1) {
              finalArray.push(results.pop());
            }
          } else {
            if (holons.length >= 2) {
              finalArray.push(holons.pop());
              finalArray.push(holons.pop());
            } else if (holons.length === 1) {
              finalArray.push(holons.pop());
            }
          }

          resultsTurn = !resultsTurn;
        }

        return finalArray;
      };
      const toColumns = (elements: any[]): any[][] => {
        const COLUMN_COUNT = window.innerWidth < 767 ? 2 : 3;
        let grid: any[][] = [];

        for (let i = 0; i < COLUMN_COUNT; i++) {
          grid.push([]);
        }

        elements.forEach((elem, index) => {
          const modulus = index % COLUMN_COUNT;
          grid[modulus].push(elem);
        });

        return grid;
      };
      const paint = (): void => {
        const dataset = createDataset(localResults.value, localFacets.value, activeFilter.value);
        const orderedDataset = reorder(dataset.children);
        grid.value = toColumns(orderedDataset);
      };
      // Custom event handling
      const updateHolons = (facets: Facet[]): void => {
        localFacets.value = facets;
        paint();
      };

      const updateResults = (results: Result[]): void => {
        localResults.value = results;
        paint();
      };

      const throttle = (func: () => void, wait: number): (() => void) => {
        let waiting = false;
        return function (): void {
          if (!waiting) {
            func();
            waiting = true;
            setTimeout(() => {
              func();
              waiting = false;
            }, wait);
          }
        };
      };

      const watchResize = throttle(() => paint(), 300);

      $x.on('ResultsChanged', false).subscribe(updateResults);
      $x.on('FacetsChanged', false).subscribe(updateHolons);

      onMounted(() => {
        window.addEventListener('resize', watchResize);
        paint();
      });

      onBeforeUnmount(() => {
        window.removeEventListener('resize', watchResize);
      });

      return {
        localFacets,
        localResults,
        grid,
        paint,
        getBackgroundColor,
        ResultCard,
        HolonCard
      };
    }
  });
</script>

<style lang="scss" scoped>
  .grid {
    display: flex;
    gap: 12px;
    margin-bottom: 20px;

    &__column {
      display: flex;
      flex-direction: column;
      flex: 1;
      gap: 12px;
    }
  }
</style>
