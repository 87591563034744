<template>
  <ul class="breadcrumbs">
    <li class="breadcrumbs__elem" v-if="activeFilter.length > 0">
      <BaseEventButton class="breadcrumbs__filter" :events="{ UserDeselectedAllHolons: undefined }">
        <BackIcon class="breadcrumbs__back-icon" />
      </BaseEventButton>
    </li>
    <li v-for="filter in activeFilter">
      <BaseEventButton
        :class="[
          'breadcrumbs__filter',
          'breadcrumbs__filter--current',
          {
            'breadcrumbs__filter--inactive': filter != activeFilter[activeFilter.length - 1]
          }
        ]"
        :style="`--filter-color: ${getColor(filter)}`"
        :events="{ UserDeselectedHolon: filter }"
      >
        <span class="breadcrumbs__filter-text">{{ filter.label }}</span>
        <BackIcon
          class="breadcrumbs__back-icon breadcrumbs__back-icon--filter"
          v-if="filter != activeFilter[activeFilter.length - 1]"
        />
      </BaseEventButton>
    </li>
  </ul>
</template>

<script lang="ts" setup>
  import { BaseEventButton, useState, VueCSSClasses } from '@empathyco/x-components';
  import { HierarchicalFilter } from '@empathyco/x-types';
  import { getSectionColor } from '../scripts/utils';
  import { BackIcon } from './icons';

  const { activeFilter } = useState('facets', ['activeFilter']);
  const getColor = (filter: HierarchicalFilter): VueCSSClasses => getSectionColor(filter.root).base;
</script>

<style lang="scss" scoped>
  .breadcrumbs {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    padding-left: 0;
    margin-top: 0;
    gap: rem(8px);

    &__back-icon {
      width: rem(12px);
      &--filter {
        margin-top: rem(-2px);
        margin-left: rem(5px);
      }
    }

    &__filter {
      display: flex;
      align-items: center;
      color: #243d48;
      border-radius: rem(22px);
      border: solid #243d48 rem(1px);
      background-color: white;
      padding: rem(8px) rem(10px);
      margin-bottom: rem(8px);
      min-height: rem(30px);
      text-transform: uppercase;
      font-family: 'Montserrat', sans-serif;
      font-size: $font-size--2xs;
      line-height: rem(12px);
      letter-spacing: $letter-spacing--sm;
      font-weight: $font-weight--medium;
      cursor: pointer;

      &--current {
        border-color: transparent;
        background-color: var(--filter-color);
      }
      &--inactive {
        background-color: white;
        border-color: var(--filter-color);
      }
    }

    &__filter-text {
      max-width: rem(140px);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    @media screen and (max-width: $breakpoint__tablet--min) {
      padding-left: 20px;
      padding-right: 20px;
      margin-left: -20px;
      margin-right: -50px;
      flex-wrap: nowrap;
      max-width: calc(100vw - 40px);
      overflow-x: auto;
      margin-top: rem(16px);
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
</style>
