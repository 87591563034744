<template>
  <div class="no-results">
    <div class="no-results__image-wrapper">
      <NoResultsEyesIcon class="no-results__image no-results__image--eyes" />
      <NoResultsMouthIcon class="no-results__image no-results__image--mouth" />
    </div>
    <p class="no-results__big-text">
      No results for: "
      <i>{{ $x.query.searchBox }}"</i>
    </p>
    <p class="no-results__small-text">Maybe you're interested in:</p>
    <div class="no-results__link-container">
      <a
        v-for="suggestion in noResultsSuggestions"
        :class="`no-results__link no-results__link--${suggestion.section}`"
        :href="suggestion.url"
      >
        <DocumentIcon class="no-results__link-icon" />
        <p class="no-results__link-text">{{ suggestion.root }}</p>
        <p class="no-results__breadcrumbs">{{ suggestion.breadcrumbs }}</p>
      </a>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import suggestions from '../data/no-results-suggestions.json';
  import { DocumentIcon, NoResultsEyesIcon, NoResultsMouthIcon } from './icons';

  export default defineComponent({
    name: 'NoResults',
    components: {
      DocumentIcon,
      NoResultsEyesIcon,
      NoResultsMouthIcon
    },
    setup() {
      const noResultsSuggestions = suggestions;

      return {
        noResultsSuggestions
      };
    }
  });
</script>

<style lang="scss" scoped>
  .no-results {
    $class-component: &;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;

    &__image-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 100px;
      height: 81px;
      margin: 46px auto 55px;
    }

    &__image {
      &--eyes {
        @keyframes blink {
          45%,
          55% {
            transform: scaleY(1);
          }
          50% {
            transform: scaleY(0.1);
          }
        }
        animation-name: blink;
        animation-duration: 6s;
        animation-timing-function: ease;
        animation-iteration-count: infinite;
      }
      &--mouth {
        position: relative;
        left: 2px;
      }
    }

    &__big-text {
      font-size: 32px;
      line-height: 40px;
      font-weight: 500;
      color: #1b2d36;
      margin-top: 52px;
    }

    &__small-text {
      font-size: 24px;
      line-height: 30px;
      font-weight: 300;
      color: #1b2d36;
      margin-bottom: 40px;
    }

    &__link-container {
      display: flex;
      justify-content: center;
      gap: 27px;
    }
    &__link {
      $link-component: &;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 136px;
      height: 136px;
      background-color: var(--background-color, #f8d7d0);
      cursor: pointer;
      text-decoration: none;
      color: #1b2d36;
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
      border-radius: 9999px;
      border: solid 4px var(--background-color, #f8d7d0);
      margin: 0;
      transition-property: width, height, margin;
      transition-timing-function: ease;
      transition-duration: 200ms;

      &:hover {
        background-color: #fff;
        filter: drop-shadow(0 8px 8px var(--shadow-color, rgb(53 16 58 / 30%)));
        width: 232px;
        height: 232px;
        position: relative;
        margin: -48px;

        #{$class-component}__breadcrumbs {
          display: block;
        }
      }

      &--understand {
        --background-color: #e8e0e9;
        --shadow-color: rgb(53 16 58 / 30%);
      }

      &--onboard {
        --background-color: #cbeaef;
        --shadow-color: rgb(203 234 239 / 30%);
      }

      &--play {
        --background-color: #d9ece3;
        --shadow-color: rgb(29 135 83 / 30%);
      }

      &--develop {
        --background-color: #ffefce;
        --shadow-color: rgb(158 110 1 / 30%);
      }
    }

    &__link-text {
      display: inline-block;
      margin-bottom: 8px;
      width: 136px;
    }

    &__link-icon {
      transform: scale(1.5);
    }

    &__breadcrumbs {
      display: none;
      font-weight: 300;
      font-size: 12px;
      width: calc(232px * 0.9);
      margin: 0;
    }
  }
</style>
