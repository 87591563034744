<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    opacity="0.43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="12" fill="#008094" />
  </svg>
</template>

<script lang="ts">
  export default {};
</script>
