<template>
  <svg width="100" height="26" viewBox="0 0 100 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12.7976" cy="12.7976" r="12.7976" fill="#E3E6E7" />
    <circle cx="88.3923" cy="12.7966" r="11.6071" fill="#E3E6E7" />
  </svg>
</template>

<script lang="ts">
  export default {};
</script>
