<template>
  <div class="no-results">
    <p class="no-results__small-text">No results for:</p>
    <p class="no-results__big-text">
      <i>"{{ $x.query.searchBox }}"</i>
    </p>
    <div class="no-results__image-wrapper">
      <NoResultsEyesIcon class="no-results__image no-results__image--eyes" />
      <NoResultsMouthIcon class="no-results__image no-results__image--mouth" />
    </div>
    <p class="no-results__small-text">Maybe you're interested in:</p>
    <div class="no-results__grid">
      <div class="no-results__column" v-for="column in suggestions">
        <ResultCard v-if="column.root" :elem="column" class="no-results__elem" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import suggestions from '../../data/no-results-suggestions.json';
  import { Component } from 'vue-property-decorator';
  import { NoResultsEyesIcon, NoResultsMouthIcon } from '../icons';
  import ResultCard from '@/components/mobile/ResultCard.vue';

  @Component({
    components: {
      NoResultsEyesIcon,
      NoResultsMouthIcon,
      ResultCard
    }
  })
  export default class NoResultsMobile extends Vue {
    public suggestions = suggestions;
  }
</script>

<style lang="scss" scoped>
  .no-results {
    $class-component: &;
    top: 64px;
    left: 0;
    width: 100%;
    height: 100%;

    &__image-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 100px;
      height: 81px;
      margin: 46px auto 55px;
    }

    &__image {
      &--eyes {
        @keyframes blink {
          45%,
          55% {
            transform: scaleY(1);
          }
          50% {
            transform: scaleY(0.1);
          }
        }
        animation-name: blink;
        animation-duration: 6s;
        animation-timing-function: ease;
        animation-iteration-count: infinite;
      }
      &--mouth {
        position: relative;
        left: 2px;
      }
    }

    &__big-text {
      font-size: 20px;
      line-height: 25px;
      font-weight: 600;
      color: #1b2d36;
      margin-top: -14px;
    }

    &__small-text {
      font-size: 14px;
      line-height: 21px;
      font-weight: 400;
      color: #243d48;
    }

    &__grid {
      display: flex;
      flex-wrap: wrap;
      max-width: 100%;
      padding-right: 12px;
      padding-left: 12px;
      gap: 12px;
    }

    &__column {
      display: flex;
      width: calc(50% - 6px);
    }

    &__elem {
      width: 100%;
    }
  }
</style>
