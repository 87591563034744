<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.285 9.375L5.4165 9.375C5.07133 9.375 4.7915 9.65482 4.7915 10C4.7915 10.3452 5.07133 10.625 5.4165 10.625L14.285 10.625C14.6301 10.625 14.91 10.3452 14.91 10C14.91 9.65482 14.6301 9.375 14.285 9.375Z"
      fill="#243D48"
    />
  </svg>
</template>

<script lang="ts">
  export default {};
</script>
