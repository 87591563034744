<template>
  <div class="landing">
    <div class="landing__text">
      <div class="landing__big-text">
        <p class="landing__first-text">Welcome to the</p>
        <p class="landing__big-text landing__big-text--line2">holon search experience</p>
      </div>
      <p class="landing__small-text">
        An evolving and self-organizing system for content
        <br />
        search, where each holon result is simultaneously a
        <br />
        whole and part of something bigger...
      </p>
    </div>
    <div class="landing__bubbles">
      <div class="landing__bubble landing__bubble--yellow-first">
        <LandingSubholonsIcon class="landing__subholons" />
        <p>Related topics are grouped together</p>
      </div>
      <div class="landing__bubble landing__bubble--yellow-second">
        <DocumentIcon class="landing__doc-icon" />
        <p>The doc icon links to a specific doc</p>
      </div>
      <div class="landing__bubble landing__bubble--blue">
        <Spark class="landing__spark-icon" />
        <p>Sparkles highlight recommended docs</p>
      </div>
      <div class="landing__bubble landing__bubble--purple">
        <p>Size indicates relevance. The larger, the more relevant</p>
      </div>
      <div class="landing__bubble landing__bubble--green">
        <p>Color indicates categories</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import { BaseEventButton } from '@empathyco/x-components';
  import { LandingSubholonsIcon, DocumentIcon, Spark } from './icons';

  export default defineComponent({
    name: 'Landing',
    components: {
      BaseEventButton,
      LandingSubholonsIcon,
      DocumentIcon,
      Spark
    }
  });
</script>

<style lang="scss" scoped>
  .landing {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__text {
      position: relative;
      margin-top: 68px;
    }
    &__first-text {
      margin-top: 0;
      margin-bottom: 8px;
    }
    &__big-text {
      color: #243d48;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 30px;
      &--line2 {
        font-weight: 600;
        font-size: 32px;
        line-height: 40px;
        margin: 0;
      }
    }
    &__small-text {
      color: #243d48;
      font-family: 'Lora';
      font-style: italic;
      font-weight: 400;
      font-size: 28px;
      line-height: 45px;
      margin-top: 20px;
    }

    &__bubbles {
      position: relative;
    }

    &__bubble {
      position: absolute;
      border-radius: 9999px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      p {
        position: relative;
        font-weight: 500;
        font-size: 20px;
        line-height: 25px;
        color: #243d48;
        margin: 0 14px;
      }

      &--yellow-first {
        width: 160px;
        height: 160px;
        background-color: #ffefce;
        left: -250px;
        top: 220px;

        p {
          width: 120px;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
        }
      }
      &--yellow-second {
        width: 160px;
        height: 160px;
        background-color: #ffefce;
        left: -400px;
        top: 140px;
        p {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
        }
      }
      &--blue {
        width: 160px;
        height: 160px;
        background-color: #cbeaef;
        left: -258px;
        top: 43px;
        border: 4px solid #008094;
        p {
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
        }
      }
      &--purple {
        width: 336px;
        height: 336px;
        background-color: #f3eff4;
        left: -80px;
        top: 41px;
        p {
          width: 250px;
        }
      }
      &--green {
        width: 160px;
        height: 160px;
        background-color: #d9ece3;
        left: 277px;
        top: 130px;
        p {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }

    &__subholons {
      position: absolute;
      width: 120px;
      height: 120px;
      top: 18px;
    }

    &__doc-icon {
      margin-bottom: 12px;
    }

    &__spark-icon {
      position: absolute;
      background-color: #008094;
      border-radius: 20px;
      top: 7px;
      left: 123px;
      padding: 4px;
    }
  }
</style>
