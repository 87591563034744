<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.75031 12C6.91865 12.0062 7.08418 11.9556 7.22023 11.8563C7.35629 11.7569 7.45495 11.6147 7.50031 11.4525C7.72 10.4883 8.20755 9.60575 8.90681 8.90648C9.60607 8.20722 10.4886 7.71968 11.4528 7.49999C11.6086 7.45455 11.7454 7.35981 11.8428 7.22999C11.9402 7.10016 11.9928 6.94226 11.9928 6.77999C11.9928 6.61771 11.9402 6.45981 11.8428 6.32999C11.7454 6.20016 11.6086 6.10542 11.4528 6.05999C10.4785 5.84082 9.58778 5.34599 8.88695 4.63453C8.18613 3.92306 7.70477 3.025 7.50031 2.04749C7.50031 1.84857 7.42129 1.65781 7.28064 1.51716C7.13999 1.3765 6.94922 1.29749 6.75031 1.29749C6.5514 1.29749 6.36063 1.3765 6.21998 1.51716C6.07933 1.65781 6.00031 1.84857 6.00031 2.04749C5.78062 3.01168 5.29308 3.89422 4.59381 4.59349C3.89455 5.29275 3.01201 5.7803 2.04781 5.99999C1.89203 6.04542 1.75518 6.14016 1.65781 6.26999C1.56045 6.39981 1.50781 6.55771 1.50781 6.71999C1.50781 6.88226 1.56045 7.04016 1.65781 7.16999C1.75518 7.29981 1.89203 7.39455 2.04781 7.43999C3.01975 7.66424 3.90769 8.16073 4.60768 8.87135C5.30767 9.58196 5.79073 10.4773 6.00031 11.4525C6.04568 11.6147 6.14434 11.7569 6.28039 11.8563C6.41645 11.9556 6.58198 12.0062 6.75031 12ZM4.24531 6.74999C5.30298 6.17211 6.17244 5.30265 6.75031 4.24499C7.32818 5.30265 8.19765 6.17211 9.25531 6.74999C8.19765 7.32786 7.32818 8.19732 6.75031 9.25499C6.17244 8.19732 5.30298 7.32786 4.24531 6.74999Z"
      fill="white"
    />
    <path
      d="M13.7028 17.25C12.9211 17.073 12.2055 16.6782 11.6388 16.1115C11.0721 15.5448 10.6773 14.8292 10.5003 14.0475C10.4549 13.8917 10.3601 13.7549 10.2303 13.6575C10.1005 13.5601 9.94259 13.5075 9.78031 13.5075C9.61803 13.5075 9.46013 13.5601 9.33031 13.6575C9.20049 13.7549 9.10575 13.8917 9.06031 14.0475C8.87875 14.8369 8.47509 15.558 7.89699 16.1254C7.3189 16.6929 6.5905 17.0831 5.79781 17.25C5.64203 17.2954 5.50518 17.3902 5.40781 17.52C5.31045 17.6498 5.25781 17.8077 5.25781 17.97C5.25781 18.1323 5.31045 18.2902 5.40781 18.42C5.50518 18.5498 5.64203 18.6445 5.79781 18.69C6.58727 18.8715 7.3083 19.2752 7.87576 19.8533C8.44322 20.4314 8.83343 21.1598 9.00031 21.9525C9.04575 22.1083 9.14049 22.2451 9.27031 22.3425C9.40013 22.4399 9.55803 22.4925 9.72031 22.4925C9.88259 22.4925 10.0405 22.4399 10.1703 22.3425C10.3001 22.2451 10.3949 22.1083 10.4403 21.9525C10.6219 21.163 11.0255 20.442 11.6036 19.8745C12.1817 19.3071 12.9101 18.9169 13.7028 18.75C13.8586 18.7045 13.9954 18.6098 14.0928 18.48C14.1902 18.3502 14.2428 18.1923 14.2428 18.03C14.2428 17.8677 14.1902 17.7098 14.0928 17.58C13.9954 17.4502 13.8586 17.3554 13.7028 17.31V17.25ZM9.75031 19.8375C9.297 19.0842 8.66613 18.4533 7.91281 18C8.66962 17.5511 9.30147 16.9193 9.75031 16.1625C10.1952 16.9223 10.828 17.5551 11.5878 18C10.831 18.4488 10.1992 19.0807 9.75031 19.8375Z"
      fill="white"
    />
    <path
      d="M23.0253 10.335C22.9257 10.2379 22.8011 10.1703 22.6653 10.14C21.4282 9.86324 20.2953 9.24031 19.3989 8.3439C18.5025 7.4475 17.8796 6.31461 17.6028 5.07749C17.5818 4.97495 17.5397 4.87794 17.4789 4.79269C17.4182 4.70743 17.3404 4.63583 17.2503 4.58249C17.1201 4.51623 16.9734 4.48945 16.8281 4.50544C16.6829 4.52143 16.5455 4.57949 16.4328 4.67249C16.3056 4.78205 16.2163 4.92907 16.1778 5.09249C15.8988 6.33349 15.2714 7.46909 14.3693 8.36586C13.4672 9.26263 12.3279 9.88331 11.0853 10.155C10.9295 10.2004 10.7927 10.2952 10.6953 10.425C10.5979 10.5548 10.5453 10.7127 10.5453 10.875C10.5453 11.0373 10.5979 11.1952 10.6953 11.325C10.7927 11.4548 10.9295 11.5495 11.0853 11.595C12.333 11.8751 13.4755 12.5039 14.3797 13.4081C15.2839 14.3123 15.9127 15.4548 16.1928 16.7025C16.2382 16.8583 16.333 16.9951 16.4628 17.0925C16.5926 17.1899 16.7505 17.2425 16.9128 17.2425C17.0751 17.2425 17.233 17.1899 17.3628 17.0925C17.4926 16.9951 17.5874 16.8583 17.6328 16.7025C17.9056 15.4579 18.5285 14.3173 19.4281 13.415C20.3277 12.5127 21.4666 11.8865 22.7103 11.61C22.7812 11.5917 22.8493 11.5639 22.9128 11.5275C23.0004 11.4766 23.0768 11.4085 23.1374 11.3273C23.198 11.2461 23.2415 11.1535 23.2653 11.055C23.293 10.9245 23.2853 10.7889 23.2431 10.6624C23.2009 10.5358 23.1257 10.4228 23.0253 10.335ZM16.8753 14.4C16.1385 12.8587 14.8976 11.6151 13.3578 10.875C14.8942 10.1338 16.1342 8.89391 16.8753 7.35749C17.6154 8.89723 18.859 10.1382 20.4003 10.875C18.8599 11.6167 17.617 12.8595 16.8753 14.4Z"
      fill="white"
    />
  </svg>
</template>

<script lang="ts">
  export default {};
</script>
