import sections from '../data/sections.json';
import { useBreakpoints, breakpointsTailwind } from '@vueuse/core';

const config = {
  HIGHLIGHTED_BREAKPOINT: 1000,
  sections: sections as Array<{ title: string; colors: { base: string; weak: string } }>
};

const breakpoints = useBreakpoints(breakpointsTailwind);

export { config, breakpoints };
