<template>
  <div class="genai" v-if="$x.query.searchBox && loadingResponse">
    <div v-if="$x.query.searchBox && answerArray.length" class="genai__definition">
      <GenAIDisplay
        :answer="isCollapsed && shouldCollapse ? collapsedAnswer : notCollapsedAnswer"
      />

      <div class="genai__display genai__display--references">
        <h4>Sources</h4>
        <div class="genai__references">
          <a
            v-for="reference in references"
            class="genai__reference"
            :href="reference.url"
            target="_blank"
          >
            <span class="genai__reference-title">{{ reference.title }}</span>
            <ReferenceArrow />
          </a>
        </div>
      </div>

      <div class="genai__collapse-menu">
        <WarningIcon
          class="genai__warning-icon"
          @click.native="() => (showWarning = !showWarning)"
        />
        <div
          class="genai__dropdown"
          v-if="shouldCollapse"
          @click="() => (isCollapsed = !isCollapsed)"
        >
          <DropdownArrowIcon
            :class="['genai__dropdown-arrow', { 'genai__dropdown-arrow--collapsed': isCollapsed }]"
          />
        </div>
      </div>
      <GenAIFootNote v-if="showWarning" :mobile="true" />
    </div>
    <GenAIPlaceholder v-if="showPlaceholder" class="genai__definition" />
  </div>
</template>

<script lang="ts" setup>
  import { onBeforeUnmount, onMounted, ref, watch } from 'vue';
  import { use$x } from '@empathyco/x-components';
  import { WarningIcon, DropdownArrowIcon, GenAIFaceIcon } from '../icons';
  import markdownit from 'markdown-it';
  import { useEventSource } from '@vueuse/core';
  import GenAIFootNote from './GenAIFootNote.vue';
  import GenAIPlaceholder from './GenAIPlaceholder.vue';
  import GenAIDisplay from './GenAIDisplay.vue';
  import { ReferenceArrow } from '../icons';
  import { breakpoints } from '../../scripts/config';

  const emit = defineEmits(['hundle-search']);

  const answerArray = ref<string[]>([]);
  const collapsedAnswer = ref<any>(null);
  const notCollapsedAnswer = ref<any>(null);
  const shouldCollapse = ref(false);
  const isCollapsed = ref(true);
  const COLLAPSE_LIMIT = 40;
  const showWarning = ref(false);
  const loadingResponse = ref(true);
  const $x = use$x();
  const closeStream = ref(() => {});
  const references = ref<any[]>([]);
  const showPlaceholder = ref(false);

  onMounted(() => {
    window.addEventListener('resize', watchResize);
    watchResize();

    const params = new URL(location.href).searchParams;
    const query = params.get('query');

    if (query) {
      updateGenAIAnswer(query);
    }
  });

  onBeforeUnmount(() => {
    window.removeEventListener('resize', watchResize);
  });

  function checkShouldCollapse() {
    return breakpoints.isGreaterOrEqual('md') ? false : answerArray.value.length >= COLLAPSE_LIMIT;
  }

  function watchResize(): void {

    shouldCollapse.value = checkShouldCollapse();

    if (shouldCollapse.value) {
      isCollapsed.value = true;
    }
  }

  function updateGenAIAnswer(query: string): void {
    answerArray.value = [];
    isCollapsed.value = true;
    showPlaceholder.value = true;
    emit('hundle-search', false);

    const { data, close, status } = useEventSource(
      `https://exp-ai-chatbot-back.prod.empathy.co/chat?query=${query}&stream=true`
    );

    closeStream.value = close;

    watch(status, () => status.value !== 'OPEN' && close());

    watch(data, (newAnswer: string | null) => {
      if (newAnswer) {
        const objAnswer = JSON.parse(newAnswer);

        if (objAnswer.references) {
          references.value = objAnswer.references;
        }
        if (objAnswer.answer) {
          answerArray.value.push(objAnswer.answer);

          shouldCollapse.value = checkShouldCollapse();

          const md = markdownit();
          collapsedAnswer.value = md.render(
            `${answerArray.value.slice(0, COLLAPSE_LIMIT).join('')}...`,
            {
              highlight: function (/*str, lang*/) {
                return '';
              }
            }
          );
          notCollapsedAnswer.value = md.render(answerArray.value.join(''), {
            highlight: function (/*str, lang*/) {
              return '';
            }
          });
          showPlaceholder.value = false;
        }
      }
    });
  }

  $x.on('UserClearedQuery', false).subscribe(() => {
    showPlaceholder.value = false;
    closeStream.value();
    answerArray.value = [];
    emit('hundle-search', true);
  });

  $x.on('UserPressedEnterKey', false).subscribe((payload: string) => {
    showPlaceholder.value = true;
    closeStream.value();
    updateGenAIAnswer(payload);
  });
</script>

<style lang="scss">
  .genai {
    $class-component: &;
    position: relative;
    margin-right: 0;

    &__definition {
      font-family: 'Lora', serif;
      font-size: $font-size--sm;
      font-weight: $font-weight--regular;
      color: #243d48;
      margin-bottom: rem(6px);
      text-align: left;
      padding: 0 rem(20px);
      line-height: rem(21px);
    }

    &__references {
      display: flex;
      flex-wrap: wrap;
      gap: rem(8px);
      margin-bottom: rem(12px);
    }

    &__reference {
      display: flex;
      align-items: center;
      gap: rem(5px);
      background-color: $neutral--10;
      border-radius: rem(27px);
      padding: rem(8px) rem(16px);
      text-transform: uppercase;
      font-family: 'Montserrat', sans-serif;
      font-size: rem(10px);
      font-weight: 600;
      line-height: rem(12.19px);
      letter-spacing: rem(1px);
      color: $black;
      text-decoration: none;
    }

    &__reference-title {
      max-width: rem(180px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__collapse-menu {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      cursor: pointer;
    }

    &__dropdown {
      display: flex;
      align-items: center;
      margin-bottom: rem(12px);
      gap: rem(4px);
    }

    &__dropdown-arrow {
      transform: rotate(0) scale(0.7);
      transition: transform 0.5s ease;

      &--collapsed {
        transform: rotate(180deg) scale(0.7);
      }
    }

    &__warning-icon {
      display: none;
    }

    @media screen and (max-width: $breakpoint__small-tablet--max) {
      margin-left: 0;
      margin-right: 0;
      margin-top: rem(16px);

      &__face {
        display: none;
      }

      &__definition {
        padding: rem(12px) rem(16px);
      }

      &__collapse-menu {
        justify-content: space-between;
      }

      &__warning-icon {
        display: unset;
      }
    }
    @media screen and (max-width: $breakpoint__small-tablet--max) {
      margin-left: 0;
      &__definition {
        padding: rem(10px) rem(20px) rem(10px) rem(20px);
        margin-bottom: 0;
      }
    }
  }
</style>
