<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.0001 0C8.02225 0 6.08884 0.586494 4.44433 1.68532C2.79983 2.78414 1.5181 4.34594 0.761215 6.17321C0.0043329 8.00048 -0.193702 10.0112 0.192153 11.951C0.578008 13.8908 1.53042 15.6727 2.92896 17.0712C4.32749 18.4697 6.10934 19.4221 8.04916 19.808C9.98898 20.1938 11.9997 19.9958 13.8269 19.2389C15.6542 18.4821 17.216 17.2003 18.3148 15.5558C19.4137 13.9113 20.0002 11.9779 20.0002 10.0001C19.997 7.34885 18.9424 4.80711 17.0677 2.93241C15.193 1.05771 12.6513 0.00312772 10.0001 0ZM10.0001 18.1819C8.38185 18.1819 6.79997 17.7021 5.45447 16.8031C4.10897 15.904 3.06027 14.6262 2.44101 13.1311C1.82174 11.6361 1.65971 9.991 1.97541 8.40387C2.29111 6.81674 3.07036 5.35887 4.21462 4.21461C5.35887 3.07035 6.81674 2.29111 8.40387 1.97541C9.991 1.65971 11.6361 1.82174 13.1311 2.441C14.6262 3.06027 15.904 4.10896 16.8031 5.45446C17.7021 6.79997 18.182 8.38185 18.182 10.0001C18.1793 12.1692 17.3164 14.2488 15.7826 15.7826C14.2488 17.3164 12.1692 18.1793 10.0001 18.1819Z"
      fill="#919EA3"
    />
    <path
      d="M10 4.54547C9.75893 4.54547 9.5277 4.64125 9.35721 4.81174C9.18672 4.98223 9.09094 5.21346 9.09094 5.45457V11.8183C9.09094 12.0594 9.18672 12.2906 9.35721 12.4611C9.5277 12.6316 9.75893 12.7273 10 12.7273C10.2411 12.7273 10.4724 12.6316 10.6429 12.4611C10.8134 12.2906 10.9091 12.0594 10.9091 11.8183V5.45457C10.9091 5.21346 10.8134 4.98223 10.6429 4.81174C10.4724 4.64125 10.2411 4.54547 10 4.54547Z"
      fill="#919EA3"
    />
    <path
      d="M10 15.4547C10.5021 15.4547 10.9091 15.0477 10.9091 14.5456C10.9091 14.0435 10.5021 13.6365 10 13.6365C9.49796 13.6365 9.09094 14.0435 9.09094 14.5456C9.09094 15.0477 9.49796 15.4547 10 15.4547Z"
      fill="#919EA3"
    />
  </svg>
</template>

<script lang="ts">
  export default {};
</script>
