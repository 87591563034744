<template>
  <svg width="18" height="30" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="20" height="20" rx="10" fill="#243D48" />
    <path
      d="M7.34395 10C7.44915 10.0039 7.55261 9.97225 7.63765 9.91018C7.72268 9.8481 7.78434 9.75921 7.8127 9.65782C7.95 9.0552 8.25472 8.50361 8.69176 8.06657C9.1288 7.62953 9.68039 7.32481 10.283 7.18751C10.3804 7.15911 10.4659 7.0999 10.5268 7.01876C10.5876 6.93762 10.6205 6.83893 10.6205 6.73751C10.6205 6.63608 10.5876 6.53739 10.5268 6.45626C10.4659 6.37512 10.3804 6.3159 10.283 6.28751C9.67405 6.15053 9.11736 5.84126 8.67935 5.3966C8.24133 4.95193 7.94048 4.39064 7.8127 3.77969C7.8127 3.65537 7.76331 3.53614 7.6754 3.44824C7.58749 3.36033 7.46827 3.31094 7.34395 3.31094C7.21962 3.31094 7.1004 3.36033 7.01249 3.44824C6.92458 3.53614 6.8752 3.65537 6.8752 3.77969C6.73789 4.38232 6.43317 4.9339 5.99613 5.37094C5.55909 5.80798 5.00751 6.1127 4.40488 6.25001C4.30752 6.2784 4.22199 6.33762 4.16113 6.41876C4.10028 6.49989 4.06738 6.59858 4.06738 6.70001C4.06738 6.80143 4.10028 6.90012 4.16113 6.98126C4.22199 7.0624 4.30752 7.12161 4.40488 7.15001C5.01234 7.29016 5.56731 7.60047 6.0048 8.04461C6.44229 8.48874 6.74421 9.04832 6.8752 9.65782C6.90355 9.75921 6.96521 9.8481 7.05025 9.91018C7.13528 9.97225 7.23874 10.0039 7.34395 10ZM5.77832 6.71876C6.43936 6.35759 6.98278 5.81417 7.34395 5.15313C7.70511 5.81417 8.24853 6.35759 8.90957 6.71876C8.24853 7.07993 7.70511 7.62334 7.34395 8.28438C6.98278 7.62334 6.43936 7.07993 5.77832 6.71876Z"
      fill="white"
    />
    <path
      d="M11.6893 13.2813C11.2007 13.1706 10.7535 12.9239 10.3992 12.5697C10.045 12.2155 9.79834 11.7682 9.6877 11.2797C9.6593 11.1823 9.60008 11.0968 9.51895 11.0359C9.43781 10.9751 9.33912 10.9422 9.23769 10.9422C9.13627 10.9422 9.03758 10.9751 8.95644 11.0359C8.87531 11.0968 8.81609 11.1823 8.78769 11.2797C8.67422 11.7731 8.42193 12.2237 8.06062 12.5784C7.69931 12.9331 7.24407 13.177 6.74863 13.2813C6.65127 13.3097 6.56574 13.3689 6.50488 13.45C6.44403 13.5311 6.41113 13.6298 6.41113 13.7313C6.41113 13.8327 6.44403 13.9314 6.50488 14.0125C6.56574 14.0936 6.65127 14.1529 6.74863 14.1813C7.24204 14.2947 7.69269 14.547 8.04735 14.9083C8.40202 15.2696 8.6459 15.7249 8.7502 16.2203C8.77859 16.3177 8.83781 16.4032 8.91894 16.4641C9.00008 16.5249 9.09877 16.5578 9.2002 16.5578C9.30162 16.5578 9.40031 16.5249 9.48145 16.4641C9.56258 16.4032 9.6218 16.3177 9.6502 16.2203C9.76367 15.7269 10.016 15.2763 10.3773 14.9216C10.7386 14.5669 11.1938 14.3231 11.6893 14.2188C11.7866 14.1904 11.8722 14.1311 11.933 14.05C11.9939 13.9689 12.0268 13.8702 12.0268 13.7688C12.0268 13.6673 11.9939 13.5686 11.933 13.4875C11.8722 13.4064 11.7866 13.3472 11.6893 13.3188V13.2813ZM9.21895 14.8984C8.93562 14.4276 8.54133 14.0333 8.07051 13.75C8.54351 13.4695 8.93842 13.0746 9.21895 12.6016C9.49702 13.0765 9.89248 13.4719 10.3674 13.75C9.89438 14.0305 9.49947 14.4254 9.21895 14.8984Z"
      fill="white"
    />
    <path
      d="M17.5158 8.95938C17.4536 8.89868 17.3757 8.85648 17.2908 8.83751C16.5176 8.66454 15.8096 8.27521 15.2493 7.71496C14.6891 7.1547 14.2997 6.44665 14.1268 5.67344C14.1137 5.60936 14.0873 5.54873 14.0493 5.49544C14.0114 5.44216 13.9627 5.39741 13.9064 5.36407C13.8251 5.32266 13.7333 5.30592 13.6426 5.31591C13.5518 5.32591 13.4659 5.36219 13.3955 5.42032C13.316 5.48879 13.2602 5.58068 13.2361 5.68282C13.0618 6.45845 12.6696 7.1682 12.1058 7.72868C11.542 8.28916 10.83 8.67709 10.0533 8.84688C9.95595 8.87528 9.87042 8.93449 9.80957 9.01563C9.74872 9.09677 9.71582 9.19546 9.71582 9.29688C9.71582 9.3983 9.74872 9.49699 9.80957 9.57813C9.87042 9.65927 9.95595 9.71848 10.0533 9.74688C10.8331 9.92195 11.5472 10.3149 12.1123 10.8801C12.6775 11.4452 13.0704 12.1593 13.2455 12.9391C13.2739 13.0364 13.3331 13.122 13.4143 13.1828C13.4954 13.2437 13.5941 13.2766 13.6955 13.2766C13.7969 13.2766 13.8956 13.2437 13.9768 13.1828C14.0579 13.122 14.1171 13.0364 14.1455 12.9391C14.316 12.1612 14.7053 11.4483 15.2676 10.8844C15.8298 10.3205 16.5416 9.92905 17.3189 9.75626C17.3633 9.74481 17.4058 9.72747 17.4455 9.70469C17.5003 9.6729 17.548 9.63035 17.5859 9.57959C17.6238 9.52883 17.6509 9.47093 17.6658 9.40938C17.6831 9.32781 17.6783 9.24311 17.6519 9.16401C17.6256 9.08491 17.5786 9.01427 17.5158 8.95938ZM13.6721 11.5C13.2116 10.5367 12.436 9.75944 11.4736 9.29688C12.4339 8.83366 13.2088 8.05871 13.6721 7.09844C14.1346 8.06079 14.9119 8.83637 15.8752 9.29688C14.9124 9.76043 14.1356 10.5372 13.6721 11.5Z"
      fill="white"
    />
  </svg>
</template>

<script lang="ts">
  export default {};
</script>
