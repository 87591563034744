<template>
  <svg width="54" height="20" viewBox="0 0 54 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.821 17.7958C11.4353 18.0538 11.0277 18.3266 10.5907 18.6157C7.71182 20.5202 3.83417 19.7303 1.92968 16.8515C0.0252 13.9726 0.815073 10.095 3.69391 8.19049C4.00655 7.98367 4.33321 7.76352 4.67432 7.53363C6.72115 6.15422 9.28813 4.42425 12.4645 3.12C16.3396 1.5289 20.9179 0.605469 27.0828 0.605469C33.3821 0.605469 38.2115 1.73891 42.1297 3.38492C45.4366 4.7741 47.9983 6.51358 49.7559 7.70707C50.0184 7.8853 50.2629 8.05135 50.4893 8.20224C53.3617 10.1165 54.1383 13.9968 52.2241 16.8692C50.3098 19.7415 46.4295 20.5182 43.5572 18.6039C43.2439 18.3952 42.9417 18.1914 42.6468 17.9925C40.8818 16.8023 39.3738 15.7853 37.2885 14.9093C34.9722 13.9363 31.7962 13.1055 27.0828 13.1055C22.2348 13.1055 19.3173 13.8189 17.2124 14.6832C15.2984 15.4691 13.8708 16.4243 11.821 17.7958Z"
      fill="#E3E6E7"
    />
  </svg>
</template>

<script lang="ts">
  export default {};
</script>
