<template>
  <CloseMainModal class="holons-button">
    <div class="holons-button__icon-container">
      <div class="holons-button__icon">
        <HolonCircleIcon class="holons-button__icon--big-holon" />
        <HolonCircleIcon class="holons-button__icon--medium-holon" />
        <HolonCircleIcon class="holons-button__icon--small-holon" />
      </div>
      <CloseIcon class="holons-button__icon" />
    </div>
  </CloseMainModal>
</template>
<script lang="ts">
  import { CloseIcon, HolonCircleIcon } from '../icons';
  import { defineComponent } from 'vue';
  import { CloseMainModal } from '@empathyco/x-components';

  export default defineComponent({
    name: 'CloseButton',
    components: {
      HolonCircleIcon,
      CloseIcon,
      CloseMainModal
    }
  });
</script>

<style lang="scss" scoped>
  .holons-button {
    $component-class: &;
    background-color: #53b9c9;
    border-radius: 99999px;
    border: 1px solid #98d5df;
    box-shadow: 0 0.25rem 0.75rem 0 rgb(36 61 72 / 12%);
    cursor: pointer;
    height: 46px;
    width: 46px;
    min-width: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: 0;
    margin-right: 40px;
    margin-left: 12px;

    &__icon-container {
      position: relative;
      height: 20px;
      width: 20px;
    }
    &__icon {
      position: absolute;
      top: 0;
      left: 0;
      &--big-holon {
        position: absolute;
        transform: scale(0.9);
        top: 2px;
        left: -6px;
      }
      &--medium-holon {
        position: absolute;
        transform: scale(0.7);
        top: -4px;
        left: 6px;
      }
      &--small-holon {
        position: absolute;
        transform: scale(0.35);
        top: -15px;
        left: -5px;
      }
    }
  }
</style>
