import * as d3 from 'd3';
import config, { sectionColor } from './config';

function sizeDetailText(d: any, foreignObject: any, showDetail: boolean): void {
  /* Adapt label size and show/hide detail text*/
  const ratio = d.data.modelName === 'Result' ? 1.7 : 1.5;

  foreignObject
    .attr('x', (d: any) => (showDetail ? d.x - d.parent.x - d.r * ratio : d.x - d.parent.x - d.r))
    .attr('y', (d: any) => (showDetail ? d.y - d.parent.y - d.r * ratio : d.y - d.parent.y - d.r))
    .attr('width', (d: any) => (showDetail ? d.r * 2 * ratio : d.r * 2))
    .attr('height', (d: any) => (showDetail ? d.r * 2 * ratio : d.r * 2))
    .selectAll('.label-container')
    .attr('width', (d: any) => (showDetail ? d.r * 2 * ratio : d.r * 2))
    .selectAll('.detail');
}

export function showDetailText(svg: any, d: any, showDetail: boolean): void {
  if (d.data.modelName === 'Result' || d.data.modelName === 'HierarchicalFilter') {
    const foreignObject = svg
      .selectAll('foreignObject')
      .filter((l: any) =>
        d.data.modelName === 'Result' ? l.data.url === d.data.url : l.data.label === d.data.label
      );

    const details = foreignObject.selectAll('.detail');

    const isSafari = /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);

    if (showDetail) {
      sizeDetailText(d, foreignObject, showDetail);

      if (isSafari) {
        details.style('opacity', 1).style('max-height', '100%');
      } else {
        details.transition().duration(100).style('opacity', 1).style('max-height', '100%');
      }
    } else {
      if (isSafari) {
        details.style('opacity', 0).style('max-height', '0');
      } else {
        details.transition().duration(100).style('opacity', 0).style('max-height', '0');
      }

      // Applying text size with a delay, to letting "details" transition
      setTimeout(() => sizeDetailText(d, foreignObject, showDetail), 400);
    }
  }
}

export function urlToBreadcrumbs(url: string): string {
  const cleanedurl = url.replace('https://', '').split('/');

  if (cleanedurl.length > 2) {
    return cleanedurl.slice(1, -1).join(' / ');
  }

  return '';
}

export function getBackgroundColor(d: any, mobile = false): string {
  const isResultOrChild: boolean = d.data.parentId != null || d.data.modelName === 'Result';
  // if Background circle or Not category (like in fake circles used for layout)
  if ((!d.parent && d.data.name === 'root') || !d.data.root) {
    return mobile ? 'white' : sectionColor(d.data.root).weak;
  }
  return isResultOrChild
    ? mobile
      ? 'white'
      : sectionColor(d.data.root).weak
    : mobile
    ? sectionColor(d.data.root).weak
    : sectionColor(d.data.root).base;
}

export function resultColor(d: any): string {
  return sectionColor(d.data.root).base;
}

export function shadowColor(d: any): string {
  return sectionColor(d.data.root).shadow;
}

export function darkColor(d: any): string {
  return sectionColor(d.data.root).dark;
}

export function obtainUniqueId(d: any): string {
  if (!!d && d.data.name !== 'root') {
    const cleaned = (d.data.url ?? d.data.id)?.replace(
      /https:\/\/docs\.empathy\.co\/|\.html|\/| |:|'|\(|\)|!|"|&|\./g,
      ''
    );
    return cleaned ? cleaned : 'home';
  }
  return 'root';
}

export function zoomTransition(
  focus: any,
  event?: { altKey: any },
  d?: d3.HierarchyCircularNode<unknown>
): void {
  const isZoomIn = !!d;
  if (isZoomIn) {
    focus = d;
  }

  const duration = 400;

  const transitionCircle = d3
    .select('#transitions')
    .append('svg')
    .attr('viewBox', `-${config.WIDTH / 2} -${config.HEIGHT / 2} ${config.WIDTH} ${config.HEIGHT}`)
    .append('circle')
    .attr('class', 'transition-circle')
    .attr('r', isZoomIn ? focus.r * 2 : config.WIDTH)
    .attr('stroke', isZoomIn ? resultColor(focus) : config.colors.GREY.base)
    .attr('stroke-width', '4')
    .attr('fill', config.colors.WHITE)
    .attr(
      'transform',
      `translate(${isZoomIn ? focus.x - config.WIDTH / 2 : -config.WIDTH / 4},${
        isZoomIn ? focus.y - config.HEIGHT / 2 : -config.HEIGHT / 4
      })`
    );

  transitionCircle
    .transition()
    .duration(duration)
    .ease(d3.easeCircle)
    .attr('r', config.WIDTH / 2)
    .attr('fill', isZoomIn ? resultColor(focus) : config.colors.WHITE)
    .attr('transform', 'translate(0,0)')
    .style('opacity', 0);

  setTimeout(() => d3.select('#transitions svg').remove(), duration * 2);
}

export function getURLParameter(name: string): string | null {
  const regex = new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)');
  const results = regex.exec(window.location.href);

  if (!results || !results[1]) {
    return null;
  }

  return decodeURIComponent(results[1].replace(/\+/g, '%20')) || null;
}
