import { EndpointAdapter } from '@empathyco/x-adapter';
import {
  facetSchema,
  getFacetConfig,
  hierarchicalFilterSchema,
  platformAdapter,
  PlatformFacet,
  resultSchema,
  searchEndpointAdapter
} from '@empathyco/x-adapter-platform';

import {
  EditableNumberRangeFacet,
  HierarchicalFacet,
  HierarchicalFilter,
  NumberRangeFacet,
  Result,
  SimpleFacet
} from '@empathyco/x-types';

export const adapter = platformAdapter;

/* Code sample about how to extend the result mapper with more fields. */

interface HolonResult {
  score: number;
  title: string;
  url: string;
  root: string;
}
interface HolonHierarchicalFilter {
  isFinal: boolean;
  root: string;
}

declare module '@empathyco/x-types' {
  export interface Result {
    score: number;
    root: string;
  }
  export interface XComponentsAdapter {
    matchall: EndpointAdapter<SearchRequest, SearchResponse>;
  }
  export interface HierarchicalFilter {
    isFinal: boolean;
    root: string;
  }
}

resultSchema.$override<HolonResult, Partial<Result>>({
  score: 'score',
  name: 'title',
  url: 'url',
  root: 'root',
  price: ({ __prices: rawPrices }) => {
    if (rawPrices) {
      return {
        value: rawPrices.current.value,
        originalValue: rawPrices.previous?.value ?? rawPrices.current.value,
        futureValue: rawPrices.future?.value ?? rawPrices.current.value,
        hasDiscount:
          rawPrices.current.value < (rawPrices.previous?.value ?? rawPrices.current.value)
      };
    }

    return undefined;
  }
});

hierarchicalFilterSchema.$override<HolonHierarchicalFilter, Partial<HierarchicalFilter>>({
  isFinal: 'isFinal',
  root: 'root'
});

facetSchema.$override<
  PlatformFacet,
  Partial<HierarchicalFacet | NumberRangeFacet | SimpleFacet | EditableNumberRangeFacet>
>({
  modelName: () => 'hierarchical' as any,
  filters: {
    $path: 'values',
    $subSchema: () => getFacetConfig('hierarchical').schema
  }
});

adapter.search = searchEndpointAdapter.extends({
  /*  endpoint: 'https://search.internal.test.empathy.co/query/edocsearch/search' */
  endpoint: 'https://api.staging.empathy.co/search/v1/query/edocsearch/search'
});
