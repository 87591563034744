<template>
  <div class="genai" v-if="$x.query.searchBox && loadingResponse">
    <GenAIFaceIcon v-if="$x.query.searchBox && loadingResponse" class="genai__face" />
    <div v-if="$x.query.searchBox && answerArray.length" class="genai__definition">
      <div
        class="genai__display"
        v-html="isCollapsed && shouldCollapse ? collapsedAnswer : notCollapsedAnswer"
      />
      <div class="genai__display genai__display--references">
        <h4>References</h4>
        <div class="genai__references">
          <a
            v-for="reference in references"
            class="genai__reference"
            :href="reference.url"
            target="_blank"
          >
            <span class="genai__reference-title">{{ reference.title }}</span>
            <ReferenceArrow />
          </a>
        </div>
      </div>

      <div class="genai__collapse-menu">
        <WarningIcon
          class="genai__warning-icon--mobile"
          @click.native="() => (showWarning = !showWarning)"
        />
        <div
          class="genai__dropdown"
          v-if="shouldCollapse"
          @click="() => (isCollapsed = !isCollapsed)"
        >
          <span class="genai__dropdown-text">{{ isCollapsed ? 'Read more' : 'Collapse' }}</span>
          <DropdownArrowIcon
            :class="['genai__dropdown-arrow', { 'genai__dropdown-arrow--collapsed': isCollapsed }]"
          />
        </div>
      </div>
      <div v-if="showWarning" class="genai__foot-note genai__foot-note--mobile">
        <p>Powered by Empathy AI [beta] & Mistral. A privacy-first experience.</p>
        <p>
          Empathy AI has carefully been trained under the closed EPDocs ecosystem to provide
          accurate information. Since Empathy AI creates the responses autonomously, they may
          sometimes be imprecise.
        </p>
      </div>
    </div>
    <div
      v-else-if="$x.query.searchBox && loadingResponse"
      class="genai__definition genai__definition--placeholder"
    >
      <p>
        Reading EPDocs content and preparing an answer for you
        <span class="genai__dot">.</span>
        <span class="genai__dot">.</span>
        <span class="genai__dot">.</span>
      </p>
    </div>
    <div
      v-if="$x.query.searchBox && loadingResponse"
      class="genai__foot-note genai__foot-note--desktop"
    >
      <p>Powered by Empathy AI [beta] & Mistral. A privacy-first experience.</p>
      <div class="genai__warning-wrapper">
        <WarningIcon class="genai__warning-icon" />
        <div class="genai__warning">
          Empathy AI has carefully been trained under the closed EPDocs ecosystem to provide
          accurate information. Since Empathy AI creates the responses autonomously, they may
          sometimes be imprecise.
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, onBeforeUnmount, onMounted, ref, watch } from 'vue';
  import { BaseEventButton, use$x } from '@empathyco/x-components';
  import { BackIcon, WarningIcon, DropdownArrowIcon, GenAIFaceIcon, ReferenceArrow } from './icons';
  import markdownit from 'markdown-it';
  import { useEventSource } from '@vueuse/core';

  export default defineComponent({
    name: 'GenAI',
    components: {
      ReferenceArrow,
      GenAIFaceIcon,
      DropdownArrowIcon,
      WarningIcon,
      BackIcon,
      BaseEventButton
    },
    setup() {
      const answerArray = ref<string[]>([]);
      const collapsedAnswer = ref<any>(null);
      const notCollapsedAnswer = ref<any>(null);
      const shouldCollapse = ref(false);
      const isCollapsed = ref(true);
      const COLLAPSE_LIMIT = ref(0);
      const showWarning = ref(false);
      const loadingResponse = ref(true);
      const $x = use$x();
      const closeStream = ref(() => {});
      const references = ref<any[]>([]);

      onMounted(() => {
        window.addEventListener('resize', watchResize);
        watchResize();

        const params = new URL(location.href).searchParams;
        const query = params.get('query');

        if (query) {
          updateGenAIAnswer(query);
        }
      });

      onBeforeUnmount(() => {
        window.removeEventListener('resize', watchResize);
      });

      function watchResize(): void {
        if (window.innerWidth >= 1024) {
          COLLAPSE_LIMIT.value = 200;
        } else {
          COLLAPSE_LIMIT.value = 40;
        }

        shouldCollapse.value = answerArray.value.length >= COLLAPSE_LIMIT.value;

        if (shouldCollapse.value) {
          isCollapsed.value = true;
        }
      }

      function updateGenAIAnswer(query: string): void {
        answerArray.value = [];
        isCollapsed.value = true;

        const { data, close, status } = useEventSource(
          `https://exp-ai-chatbot-back.staging.empathy.co/chat?query=${query}&stream=true`
        );

        closeStream.value = close;

        watch(status, () => status.value !== 'OPEN' && close());

        watch(data, (newAnswer: string | null) => {
          if (newAnswer) {
            const objAnswer = JSON.parse(newAnswer);

            if (objAnswer.references) {
              references.value = objAnswer.references;
            }
            if (objAnswer.answer) {
              answerArray.value.push(objAnswer.answer);

              shouldCollapse.value = answerArray.value.length >= COLLAPSE_LIMIT.value;

              const md = markdownit();
              collapsedAnswer.value = md.render(
                `${answerArray.value.slice(0, COLLAPSE_LIMIT.value).join('')}...`,
                {
                  highlight: function (/*str, lang*/) {
                    return '';
                  }
                }
              );
              notCollapsedAnswer.value = md.render(answerArray.value.join(''), {
                highlight: function (/*str, lang*/) {
                  return '';
                }
              });
            }
          }
        });
      }

      $x.on('UserClearedQuery', false).subscribe(() => {
        closeStream.value();
        answerArray.value = [];
      });

      $x.on('UserPressedEnterKey', false).subscribe((payload: string) => {
        closeStream.value();
        updateGenAIAnswer(payload);
      });

      return {
        loadingResponse,
        answerArray,
        showWarning,
        shouldCollapse,
        isCollapsed,
        collapsedAnswer,
        notCollapsedAnswer,
        references
      };
    }
  });
</script>

<style lang="scss">
  .genai {
    $class-component: &;
    position: relative;
    margin-left: 300px;
    margin-top: 0;
    margin-right: 45px;

    &__face {
      position: absolute;
      top: 0;
      left: -50px;
    }
    &__definition {
      font-family: 'Lora', serif;
      font-size: 14px;
      font-weight: 400;
      color: #243d48;
      margin-bottom: 6px;
      text-align: left;
      border: solid 1px #f5f6f7;
      border-radius: 22px;
      background-color: #f5f6f7;
      padding: 0 20px;
      line-height: 21px;

      &--placeholder {
        color: #66777f;
      }
    }

    &__display {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-family: Montserrat;
        font-size: 18px;
        font-weight: 400;
        line-height: 25px;
        margin-top: 20px;
        margin-bottom: 10px;
      }

      h1 {
        font-size: 24px;
        line-height: 30px;
      }

      h2 {
        font-size: 20px;
      }

      h4 {
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
      }

      &:not(&--references) {
        a {
          display: block;
          text-transform: uppercase;
          color: black;
          font-family: Montserrat;
          text-decoration: none;
          letter-spacing: 1.5px;

          &:hover {
            text-decoration: underline;
          }

          &:after {
            content: ' >';
          }
        }
      }

      table {
        table-layout: fixed;
        border-spacing: 0;
        border-collapse: collapse;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        overflow: hidden;

        thead {
          tr {
            font-family: Montserrat;
            background-color: #e3e6e7;
            width: 100%;
            th {
              padding: 10px 16px;
            }
          }
        }

        tbody {
          tr {
            border-bottom: solid 2px #e3e6e7;
            width: 100%;
            td {
              padding: 10px 16px;
            }
          }
        }
      }

      blockquote,
      cite {
        display: inline-block;
        margin: 0 40px;
        font-style: italic;
      }

      pre {
        background-color: #e3e6e7;
        padding: 20px;
        border-radius: 12px;
        overflow: auto;
      }
    }

    &__references {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      margin-bottom: 12px;
    }

    &__reference {
      display: flex;
      align-items: center;
      gap: 5px;
      background-color: #e3e6e7;
      border-radius: 27px;
      padding: 8px 16px;
      text-transform: uppercase;
      font-family: Montserrat;
      font-size: 10px;
      font-weight: 600;
      line-height: 12.19px;
      letter-spacing: 1px;
      color: #243d48;
      text-decoration: none;
    }

    &__reference-title {
      max-width: 180px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__dot {
      animation-name: load;
      animation-duration: 2s;
      animation-timing-function: ease;
      animation-iteration-count: infinite;

      &:nth-child(1) {
        animation-name: load-1;
      }
      &:nth-child(2) {
        animation-name: load-2;
      }
      &:nth-child(3) {
        animation-name: load-3;
      }

      @keyframes load-1 {
        0%,
        24% {
          opacity: 0;
        }
        25%,
        99% {
          opacity: 1;
        }
      }
      @keyframes load-2 {
        0%,
        49% {
          opacity: 0;
        }
        50%,
        99% {
          opacity: 1;
        }
      }
      @keyframes load-3 {
        0%,
        74% {
          opacity: 0;
        }
        75%,
        99% {
          opacity: 1;
        }
      }
    }

    &__collapse-menu {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      cursor: pointer;
    }
    &__dropdown-text {
      font-family: 'Montserrat', sans-serif;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 1.5px;
      text-transform: uppercase;
      color: #66777f;
      margin: auto;
    }

    &__dropdown {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      gap: 4px;
    }

    &__dropdown-arrow {
      transform: rotate(0) scale(0.7);
      transition: transform 0.5s ease;

      &--collapsed {
        transform: rotate(180deg) scale(0.7);
      }
    }

    &__foot-note {
      box-sizing: border-box;
      font-style: italic;
      font-size: 14px;
      padding-left: 16px;
      padding-right: 16px;
      margin-bottom: 16px;
      margin-top: 0;
      width: 100%;
      text-align: left;
      color: #66777f;
      font-family: Lora;
      font-style: italic;
      line-height: 21px;

      p {
        margin: 0;
      }

      &--desktop {
        display: flex;
        justify-content: space-between;
      }
      &--mobile {
        margin: 0;
        padding-left: 0;
        display: none;
        flex-direction: column;
        gap: 12px;
        padding-top: 6px;
      }
    }

    &__warning-wrapper {
      position: relative;
      &:hover {
        #{$class-component}__warning {
          display: unset;
        }
      }
    }

    &__warning {
      display: none;
      position: absolute;
      width: 300px;
      top: 32px;
      right: -16px;
      background-color: #243d48;
      color: white;
      padding: 16px 20px 16px 20px;
      border-radius: 8px;
      font-style: normal;
      z-index: 2;

      &::before {
        position: absolute;
        top: -5px;
        right: 16px;
        content: '';
        width: 20px;
        height: 20px;
        transform: rotate(45deg);
        background-color: #243d48;
      }
    }

    &__warning-icon {
      &--mobile {
        display: none;
      }
    }

    @media screen and (max-width: 1023px) {
      margin-left: 0;
      margin-right: 0;
      margin-top: 16px;

      &__face {
        display: none;
      }

      &__definition {
        padding: 12px 16px;
      }

      &__collapse-menu {
        justify-content: space-between;
      }

      &__warning-icon {
        &--mobile {
          display: unset;
        }
      }

      &__foot-note {
        &--desktop {
          display: none;
        }
        &--mobile {
          display: flex;
        }
      }
    }

    @media screen and (max-width: 767px) {
      margin-left: 0;
    }
  }
</style>
