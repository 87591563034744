var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ul',{staticClass:"breadcrumbs"},[(_setup.activeFilter.length > 0)?_c('li',{staticClass:"breadcrumbs__elem"},[_c(_setup.BaseEventButton,{staticClass:"breadcrumbs__filter",attrs:{"events":{ UserDeselectedAllHolons: undefined }}},[_c(_setup.BackIcon,{staticClass:"breadcrumbs__back-icon"})],1)],1):_vm._e(),_vm._l((_setup.activeFilter),function(filter){return _c('li',[_c(_setup.BaseEventButton,{class:[
        'breadcrumbs__filter',
        'breadcrumbs__filter--current',
        {
          'breadcrumbs__filter--inactive': filter != _setup.activeFilter[_setup.activeFilter.length - 1]
        }
      ],style:(`--filter-color: ${_setup.getColor(filter)}`),attrs:{"events":{ UserDeselectedHolon: filter }}},[_c('span',{staticClass:"breadcrumbs__filter-text"},[_vm._v(_vm._s(filter.label))]),(filter != _setup.activeFilter[_setup.activeFilter.length - 1])?_c(_setup.BackIcon,{staticClass:"breadcrumbs__back-icon breadcrumbs__back-icon--filter"}):_vm._e()],1)],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }