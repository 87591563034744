import { HierarchicalFilter } from '@empathyco/x-types';

interface DataSet {
  name: string;
  children: any[];
}

export function createDataset(localResults: any, localFacets: any, activeFilter: any): DataSet {
  let newDataFacets: HierarchicalFilter[];

  let newDataResults = localResults;
  newDataFacets = localFacets.length > 0 ? (localFacets[0].filters as HierarchicalFilter[]) : [];

  if (!!activeFilter && activeFilter.length > 0) {
    for (let i = 0; i < activeFilter.length; i++) {
      newDataFacets = newDataFacets.filter(f => f.id !== activeFilter[i].id);
    }
    newDataResults = newDataResults.slice(0, 10);
  } else {
    newDataResults = newDataResults.slice(0, 7);
  }

  return { name: 'root', children: [...newDataFacets, ...newDataResults] };
}
