<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4 11C4 7.13401 7.13401 4 11 4C14.866 4 18 7.13401 18 11C18 12.8857 17.2543 14.5973 16.0418 15.856C16.0074 15.8824 15.9744 15.9113 15.9429 15.9428C15.9114 15.9743 15.8825 16.0073 15.8561 16.0417C14.5974 17.2543 12.8858 18 11 18C7.13401 18 4 14.866 4 11ZM16.6177 18.0319C15.0781 19.2635 13.125 20 11 20C6.02944 20 2 15.9706 2 11C2 6.02944 6.02944 2 11 2C15.9706 2 20 6.02944 20 11C20 13.125 19.2635 15.078 18.0319 16.6176L21.7071 20.2928C22.0976 20.6833 22.0976 21.3165 21.7071 21.707C21.3166 22.0975 20.6834 22.0975 20.2929 21.707L16.6177 18.0319Z"
      fill="#919EA3"
    />
  </svg>
</template>

<script lang="ts">
  export default {};
</script>
