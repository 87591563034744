<template>
  <ul v-if="!$x.query.searchBox" class="suggestions">
    <li v-for="suggestion in landingSuggestions">
      <BaseEventButton
        class="suggestions__suggestion"
        :events="{ UserAcceptedAQuery: suggestion, UserPressedEnterKey: suggestion }"
      >
        {{ suggestion.toUpperCase() }}
      </BaseEventButton>
    </li>
  </ul>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import { BaseEventButton } from '@empathyco/x-components';
  import suggestions from '../../data/landing-suggestions.json';

  export default defineComponent({
    name: 'Suggestions',
    components: {
      BaseEventButton
    },
    setup() {
      const landingSuggestions = suggestions;

      return {
        landingSuggestions
      };
    }
  });
</script>

<style lang="scss" scoped>
  .suggestions {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    margin-left: 100px;
    padding-left: 0;
    margin-top: 16px;

    &__suggestion {
      margin-right: 8px;
      margin-bottom: 6px;
      padding: 8px 10px;
      border: solid 1px #f5f6f7;
      border-radius: 22px;
      background-color: #f5f6f7;
      text-transform: uppercase;
      font-family: 'Montserrat', sans-serif;
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 1px;
      cursor: pointer;
      color: #243d48;
    }
  }

  @media screen and (max-width: 1023px) {
    .suggestions {
      margin-left: 40px;
      margin-right: -60px;
    }
  }

  @media screen and (max-width: 767px) {
    .suggestions {
      margin-left: 0;
      margin-right: -50px;
    }
  }
</style>
