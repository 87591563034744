import { config } from './config';

export const getSectionColor = (title: string) => {
  const section = config.sections.find(
    (section: any) => section.title.toUpperCase() === title.toUpperCase()
  );
  return section ? section.colors : config.sections[config.sections.length - 1].colors;
};

export function getBackgroundColor(d: any): string {
  const isResultOrChild: boolean = d.data.parentId != null || d.data.modelName === 'Result';
  // if Background circle or Not category (like in fake circles used for layout)
  if ((!d.parent && d.data.name === 'root') || !d.data.root) {
    return 'white';
  }
  return isResultOrChild ? 'white' : getSectionColor(d.data.root).weak;
}

export function getResultColor(d: any): string {
  return getSectionColor(d.data.root).base;
}

export function getURLParameter(name: string): string | null {
  const regex = new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)');
  const results = regex.exec(window.location.href);

  if (!results?.[1]) {
    return null;
  }

  return decodeURIComponent(results[1].replace(/\+/g, '%20')) || null;
}
