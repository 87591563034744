<template>
  <ResultsList>
    <BaseVariableColumnGrid class="x-grid x-list x-list--aling-center x-list--justify-center">
      <template #result="{ item: result }">
        <Result :result="result" />
      </template>
    </BaseVariableColumnGrid>
  </ResultsList>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import { ResultsList } from '@empathyco/x-components/search';
  import { BaseVariableColumnGrid } from '@empathyco/x-components';
  import Result from './Result.vue';

  export default defineComponent({
    name: 'Results',
    components: {
      ResultsList,
      BaseVariableColumnGrid,
      Result
    }
  });
</script>

<style></style>
