<template>
  <ul v-if="!$x.query.searchBox" class="suggestions">
    <li v-for="suggestion in landingSuggestions">
      <BaseEventButton
        class="suggestions__suggestion"
        :events="{ UserAcceptedAQuery: suggestion, UserPressedEnterKey: suggestion }"
      >
        {{ suggestion }}
      </BaseEventButton>
    </li>
  </ul>
</template>

<script lang="ts" setup>
  import { ref } from 'vue';
  import { BaseEventButton } from '@empathyco/x-components';
  import suggestions from '../../data/landing-suggestions.json';

  const landingSuggestions = ref(suggestions);
</script>

<style lang="scss" scoped>
  .suggestions {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    padding-left: rem(22px);
    padding-top: rem(24px);
    gap: rem(16px);
    margin-top: rem(16px);

    &__suggestion {
      display: flex;
      width: rem(203.75px);
      height: rem(107px);
      padding: rem(12px);
      border: solid rem(1px) $neutral--10;
      border-radius: rem(24px);
      background-color: $background-grey;
      font-family: 'Montserrat', sans-serif;
      font-weight: $font-weight--medium;
      font-size: $font-size--sm;
      line-height: rem(21px);
      text-align: left;
      cursor: pointer;
      color: #243d48;
    }
    @media screen and (max-width: 1023px) {
      justify-content: center;
      padding-left: 0;
      margin-left: rem(40px);
      margin-right: rem(-60px);
    }

    @media screen and (max-width: 767px) {
      margin-left: 0;
      margin-right: rem(-50px);
    }
  }
</style>
