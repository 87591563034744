<template>
  <div
    class="grid-elem"
    @click="() => clickedHolon(elem)"
    :style="`--background-color: ${getBackgroundColor({ data: elem })}`"
  >
    <div class="grid-elem__top-section">
      <div class="grid-elem__pill" :style="`background-color: ${resultColor({ data: elem })}`">
        {{ elem.root ? elem.root.split(' ')[0] : '' }}
      </div>
      <component
        :is="opened ? Minus : Plus"
        class="grid-elem__icon"
        @click.native.stop="
          () => {
            opened = !opened;
          }
        "
      />
    </div>
    <p class="grid-elem__title">{{ elem.label }}</p>
    <CollapseHeight>
      <ul class="grid-elem__children" v-if="opened">
        <li class="grid-elem__child" v-if="child.label" v-for="child of elem.children">
          {{ child.label }}
        </li>
      </ul>
    </CollapseHeight>
  </div>
</template>

<script lang="ts" setup>
  import { ref } from 'vue';
  import { getBackgroundColor, getResultColor } from '../scripts/utils';
  import { CollapseHeight, use$x } from '@empathyco/x-components';
  import Plus from './icons/plus.vue';
  import { SimpleFilter } from '@empathyco/x-types';
  import Minus from './icons/minus.vue';

  interface Props {
    elem: Object;
  }

  defineProps<Props>();

  const opened = ref(false);
  const $x = use$x();

  const resultColor = (elem: any): string => {
    return getResultColor(elem);
  };

  const clickedHolon = (elem: SimpleFilter) => {
    $x.emit('UserClickedASimpleFilter', elem);
    $x.emit('UserClickedAFilter', elem);
    $x.emit('UserSetActiveFilter', elem);
  };
</script>

<style lang="scss" scoped>
  .grid-elem {
    padding: rem(12px);
    border-radius: rem(24px);
    background-color: var(--background-color);
    border: rem(1px) solid var(--background-color);
    text-decoration: none;
    color: #243d48;
    height: fit-content;
    overflow: hidden;

    &__top-section {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }

    &__pill {
      border-radius: rem(9999px);
      text-transform: uppercase;
      width: fit-content;
      height: fit-content;
      font-size: $font-size--xs;
      font-weight: $font-weight--semibold;
      line-height: rem(14.63px);
      letter-spacing: $letter-spacing--s;
      padding: rem(4px) rem(6px);
    }

    &__title {
      font-size: $font-size--sm;
      font-weight: $font-weight--medium;
      line-height: rem(21px);
      text-align: left;
      margin-bottom: 0;
    }

    &__children {
      list-style-type: none;
      padding-left: rem(20px);
      margin: 0;
    }
    &__child {
      position: relative;
      text-align: left;
      margin-bottom: rem(10px);
      font-size: $font-size--xs;
      &:first-child {
        margin-top: rem(10px);
      }
      &::before {
        position: absolute;
        left: rem(-15px);
        content: '•';
      }
    }
  }
</style>
