<template>
  <div class="header">
    <Logo class="logo" />
    <div class="x-list__item--expand x-margin--bottom-auto search">
      <SearchBox class="searchbox" />
      <Suggestions />
    </div>
    <CloseButton class="close-button" />
  </div>
</template>
<script lang="ts">
  import { BackIcon } from '../icons';
  import { BaseEventButton } from '@empathyco/x-components';
  import { defineComponent } from 'vue';
  import SearchBox from '@/components/header/SearchBox.vue';
  import Logo from '@/components/header/Logo.vue';
  import CloseButton from '@/components/header/CloseButton.vue';
  import Suggestions from '@/components/header/Suggestions.vue';

  export default defineComponent({
    name: 'Header',
    components: {
      Suggestions,
      BaseEventButton,
      BackIcon,
      CloseButton,
      SearchBox,
      Logo
    }
  });
</script>

<style lang="scss" scoped>
  .header {
    display: flex;
    width: 100vw;
    flex-flow: row nowrap;
  }
  .edoc-logo {
    display: block;
    outline: none;
    &:hover {
      text-decoration: none;
    }
    &__image {
      width: 100%;
      margin-bottom: 0;
      transform: scale(0.95);
    }
  }
  .search {
    margin-top: 65px;
    width: 100%;
  }
  .searchbox {
    margin-left: 100px;
  }
  .logo {
    padding: 40px 0 0;
    min-width: 200px;
  }

  .close-button {
    margin-top: 65px;
  }

  @media screen and (max-width: 1023px) {
    .header {
      display: flex;
      width: 100vw;
      flex-flow: row nowrap;
    }
    .edoc-logo {
      display: none;
    }
    .search {
      margin-top: 20px;
      width: 100%;
    }
    .searchbox {
      margin-left: 40px;
    }

    .close-button {
      margin-top: 20px;
      margin-left: 12px;
      margin-right: 40px;
    }
  }
  @media screen and (max-width: 767px) {
    .searchbox {
      margin-left: 0;
    }
    .close-button {
      margin-right: 0;
    }
  }
</style>
