<template>
  <div class="x-input-group x-margin--top-00 x-input-group--card x-border-radius--20 search-bar">
    <SearchInput
      class="search-input"
      :instant="false"
      tabindex="1"
      :placeholder="placeholder"
      :maxLength="120"
    />
    <ClearSearchInput v-if="$x.query.searchBox" class="x-input-group-button clear-search-input">
      Clear
    </ClearSearchInput>
    <SearchIcon v-else class="search-icon" />
  </div>
</template>

<script lang="ts">
  import { defineComponent, onMounted, ref } from 'vue';
  import { SearchInput, SearchButton, ClearSearchInput } from '@empathyco/x-components/search-box';
  import { SearchIcon } from '../icons';

  export default defineComponent({
    name: 'SearchBox',
    components: {
      SearchInput,
      SearchButton,
      ClearSearchInput,
      SearchIcon
    },
    setup() {
      const Originalplaceholder = 'Love AI. Love Privacy. Type away!';

      const placeholder = ref('');

      const fillPlaceholder = (): void => {
        const characters = Originalplaceholder.split('');
        characters.forEach((character, index) => {
          setTimeout(() => {
            placeholder.value += character;
          }, 75 * index);
        });
      };

      onMounted(() => {
        fillPlaceholder();
      });

      return {
        placeholder
      };
    }
  });
</script>

<style lang="scss" scoped>
  .search-bar {
    border: solid 1px #243d48;
    height: 44px;
    padding-left: 22px;
    padding-right: 17px;

    .search-input {
      font-size: 16px;

      &:focus {
        outline: none;
      }
    }

    .search-input::placeholder {
      font-size: 16px;
      font-weight: 400;
      color: #919ea3;
    }

    .search-button {
      padding: 0;
    }

    .clear-search-input {
      font-size: 16px;
      color: #0086b2;
      padding: 0;
    }

    @media screen and (max-width: 767px) {
      padding-left: 12px;
      padding-right: 8px;
      gap: 0;

      .search-input::placeholder {
        font-size: 14px;
      }

      .search-icon {
        width: 18px;
      }
    }

    @media screen and (max-width: 374px) {
      width: 230px;
      .search-input::placeholder {
        font-size: 11px;
      }
    }
  }
</style>
