import { HierarchicalFilter, SimpleFilter } from '@empathyco/x-types';
import {
  getBackgroundColor,
  obtainUniqueId,
  shadowColor,
  darkColor,
  showDetailText,
  urlToBreadcrumbs,
  zoomTransition
} from './utils';
import config from './config';
import * as d3 from 'd3';

const isBoosted = (d: any) => {
  return d.data.score > config.HIGHLIGHTED_BREAKPOINT;
};
const opening_proportion = (d: any) => (d.data.modelName == 'Result' ? 1.7 : 1.5);

export function createDataset(localResults: any, localFacets: any, activeFilter: any) {
  let newDataFacets: HierarchicalFilter[];

  let newDataResults = localResults;
  newDataFacets = localFacets.length > 0 ? (localFacets[0].filters as HierarchicalFilter[]) : [];

  if (!!activeFilter && activeFilter.length > 0) {
    for (let i = 0; i < activeFilter.length; i++) {
      newDataFacets = newDataFacets.filter(f => f.id != activeFilter[i].id);
    }
    newDataResults = newDataResults.slice(0, 10);
  } else {
    newDataResults = newDataResults.slice(0, 7);
  }

  var dataset = { name: 'root', children: [...newDataFacets, ...newDataResults] };

  if (dataset.children.length == 1) {
    for (var i = 0; i < 5; i++) {
      dataset.children.push({ name: 'fake', score: 0.5 });
    }
  }

  return dataset;
}

export function pack(dataset: any) {
  // Applying minimum size depending on length of "Result" title,
  // using log function to minimize the growth for bigger numbers (ex: log(10) = 2.3, log(40) = 3.7)
  const calcValue = (d: any) => {
    if (d.facetId) {
      let totalResults = d.totalResults;
      if (d.totalResults > config.HIGHLIGHTED_BREAKPOINT) {
        //Magic number. Just because it looks good
        totalResults = d.totalResults / (5 * config.HIGHLIGHTED_BREAKPOINT);
      }
      return Math.sqrt(totalResults);
    }

    let score = d.score * 7;
    if (d.score > config.HIGHLIGHTED_BREAKPOINT) {
      //Magic number. Just because it looks good
      score = d.score / (100 * config.HIGHLIGHTED_BREAKPOINT);
    }
    return Math.max(score, Math.log(d.name.length));
  };

  /* Define and pack the data for the holons */
  return d3.pack().size([config.WIDTH, config.HEIGHT]).padding(25)(
    d3.hierarchy(dataset).sum(d => (dataset.children.length > 0 ? calcValue(d) : 500))
  );
}

export function createHolonsGroups(svg: any, root: any) {
  return svg
    .selectAll('.node')
    .data(root)
    .enter()
    .append('g')
    .attr('class', (d: any) => obtainUniqueId(d.parent?.data.name === 'root' ? d : d.parent))
    .attr('data-id', (d: any) =>
      d.data.modelName == 'Result' ? 'holon-doc' : d.data.facetId ? 'holon-facet' : 'holon-parent'
    )
    .on('mouseover', (event: Event, d: any) => {
      showDetailText(svg, d, true);
    })
    .on('mouseleave', (event: Event, d: any) => {
      showDetailText(svg, d, false);
    });
}

export function createHolonsCircles(node: any, x: any, focus: any) {
  /* Add link to the final documents */
  const link = node
    .append('a')
    .attr('xlink:href', (d: any) => (d.data.url ? d.data.url : null))
    .attr('target', '_blank');

  /* Create the holon for each block */
  const circle = link
    .style('opacity', 0)
    .style('transform-origin', (d: any) =>
      d.data.name == 'root' ? null : `${d.x - 500}px ${d.y - 500}px`
    )
    .style('transform', (d: any) => (d.data.name == 'root' ? null : `scale(0)`))
    .append('circle')
    .attr('fill', (d: any) => getBackgroundColor(d))
    .attr('stroke', (d: any) => (isBoosted(d) ? darkColor(d) : null))
    .attr('stroke-width', '4')
    .style('cursor', (d: any) =>
      d.data.parentId != null || d.data.name === 'root' ? null : 'pointer'
    )
    .style('pointer-events', (d: any) =>
      d.data.parentId != null || d.data.name === 'fake' ? 'none' : null
    )
    .attr('id', (d: any) => (d.data.parentId != null ? 'subholon' : null))
    .attr('class', (d: any) => (d.name == 'root' ? 'parent' : null))
    .on('click', (event: any, d: any) => {
      if (d.data.modelName != 'Result' && focus !== d && d.depth < 2) {
        zoomTransition(focus, event, d), event.stopPropagation();
      }
      if (d.data.facetId && d.depth < 2) {
        const filter = d.data as SimpleFilter;
        x.emit('UserClickedASimpleFilter', filter);
        x.emit('UserClickedAFilter', filter);
        x.emit('UserSetActiveFilter', filter);
      }
    });

  const highlightCircle = link
    .append('g')
    .style('pointer-events', 'none')
    .classed('outer-circle', true);

  highlightCircle
    .append('circle')
    .style('opacity', 1)
    .attr('fill', (d: any) => darkColor(d))
    .attr('r', (d: any) => (isBoosted(d) ? 16 : 0));

  highlightCircle
    .append('g')
    .style('transform', 'translate(-10px, -11px) scale(0.9)')
    .append('svg')
    .attr('width', '24px')
    .attr('height', '24px')
    .html(
      `
      <use href="#spark"/>
    `
    )
    .style('opacity', (d: any) => (isBoosted(d) ? 1 : 0));

  link
    .transition()
    .duration((d: any) => (d.data.name === 'root' ? 200 : 800))
    .ease(d3.easeCircle)
    .style('opacity', 1)
    .style('transform', (d: any) => (d.data.name == 'root' ? null : `scale(1)`));

  return { circle, highlightCircle };
}

function createLabelWrapper(svg: any, root: any) {
  return svg
    .selectAll('foreignObject')
    .data(root)
    .enter()
    .filter((d: any) => !d.data.parentId)
    .append('g')
    .attr('class', (d: any) => obtainUniqueId(d))
    .attr('data-id', 'holon-label')
    .append('foreignObject')
    .style('pointer-events', 'none')
    .style('overflow', 'visible')
    .attr('width', (d: any) => d.r * 2)
    .attr('height', (d: any) => d.r * 2);
}

function createLabelTitles(label: any) {
  label
    .style('opacity', 0)
    .append('xhtml:div')
    // Applying smaller text sizes for "small" holons ("70" seems to fit the best)
    .attr('class', (d: any) => `label-container ${d.r < 70 ? 'label-container--small' : ''}`)
    .style('width', (d: any) => d.r * 2)
    .style('height', '100%')
    .style('max-height', '100%')
    .style('display', 'flex')
    .style('flex-direction', 'column')
    .style('justify-content', 'center')
    .style('align-items', 'center')
    .style('margin', 'auto')
    .append('xhtml:div')
    .style('width', (d: any) => `${d.r * opening_proportion(d)}px`) // Using smaller size than ancestor to assure texts not overflowing
    .text((d: any) =>
      d.data.modelName == 'Result'
        ? d.data.name
        : d.data.modelName == 'HierarchicalFilter'
        ? d.data.label
        : null
    )
    .attr('class', 'label') // adding temporal class for next steps
    .attr('class', (d: any) => {
      let classes = 'label';
      classes += d.data.modelName == 'Result' ? ' label--result' : ' label--filter';
      // Maximum of three lines for "tall texts"
      const titleHeight = label.select(`.${obtainUniqueId(d)} .label`).node()?.offsetHeight;
      if (titleHeight > 50) {
        // Observed minimum size for 3 lined texts
        classes += ' label--long';
      }
      return classes;
    });

  label.transition().delay(400).duration(800).style('opacity', 1);
}

function createLabelBreadcrumbs(label: any) {
  /* Print breadcrumbs inside a document */
  label
    .select('div')
    .append('xhtml:div')
    .attr('class', 'detail')
    .style('width', (d: any) => {
      //In results multiplied by 0.9 to avoid text too close to border
      //In holons multiplied by 0.75 to avoid text going out on corners
      const text_proportion = d.data.modelName == 'Result' ? 0.9 : 0.75;

      return `${d.r * 2 * opening_proportion(d) * text_proportion}px`;
    })
    .text((d: any) => {
      if (d.data.modelName == 'Result') {
        return urlToBreadcrumbs(d.data.url);
      }
      return '';
    })
    .style('opacity', 0)
    .style('max-height', 0);
}

function createLabelChildren(label: any) {
  /* Print children inside a categorie */
  label
    .select('.detail')
    .filter((d: any) => d.data.modelName == 'HierarchicalFilter' && d.data.parentId == null)
    .append('ul')
    .attr('class', 'detail-list')
    .each(function (d: any) {
      var text = label.select('ul').filter((l: any) => l.data.label === d.data.label);

      for (var i = 0; i < d.data.children.length; i++) {
        text.append('li').attr('class', 'detail-list-item').text(d.data.children[i].label);
      }
      // Temporally hidden until solved in back
      //if (d.data.totalResults > d.data.children.length) {
      //text.append('li').attr('class', 'detail-list-item detail-list-item--dots').text('...');
      //text.append('li').attr('class', 'detail-list-item detail-list-item--more').text('and more');
      //}
    });
}

function addLabelIcons(label: any) {
  /* Add icons to the final documents */
  label
    .select('div')
    .filter((d: any) => d.data.modelName == 'Result')
    .insert('svg', ':first-child')
    .attr('width', '24px')
    .attr('height', '24px').html(`
       <use href="#anchor"/>
    `);
}

export function createLabel(svg: any, root: any) {
  const label = createLabelWrapper(svg, root);
  createLabelTitles(label);
  createLabelBreadcrumbs(label);
  createLabelChildren(label);
  addLabelIcons(label);
  return label;
}

export function applyingRollover(v: any, node: any, svg: any) {
  node
    .filter((d: any) => d.data.name != 'root' && d.data.name != 'fake')
    .on('mouseenter', function (event: Event, d: any) {
      svg.selectAll(`.${obtainUniqueId(d)}`).raise();

      svg
        .select(`.${obtainUniqueId(d)}`)
        .select('circle')
        .attr('stroke', (d: any) => (isBoosted(d) ? darkColor(d) : getBackgroundColor(d)))
        .attr('stroke-width', '4')
        .attr('fill', config.colors.WHITE)
        .style('filter', (d: any) => `drop-shadow(0 8px 8px ${shadowColor(d)})`)
        .transition()
        .duration(400)
        .attr('r', (d: any) => d.r * opening_proportion(d));

      positioningHighlightCircles(
        v,
        svg.select(`.${obtainUniqueId(d)}`).select('.outer-circle'),
        true
      );
    })
    .on('mouseout', function (_: any, d: any) {
      svg
        .select(`.${obtainUniqueId(d)}`)
        .select('circle')
        .attr('stroke', (d: any) => (isBoosted(d) ? darkColor(d) : null))
        .attr('stroke-width', '4')
        .attr('fill', (d: any) => getBackgroundColor(d))
        .style('filter', '')
        .transition()
        .duration(600)
        .attr('r', (d: any) => d.r);

      positioningHighlightCircles(v, svg.select(`.${obtainUniqueId(d)}`).select('.outer-circle'));
    });
}

export function positioningHolons(v: any, circle: any, label: any) {
  const k = config.WIDTH / v[2];

  circle.attr('transform', (d: any) => `translate(${(d.x - v[0]) * k},${(d.y - v[1]) * k})`);

  circle.attr('r', (d: any) => d.r * k);

  label.attr('x', (d: any) => (d.x - v[0]) * k - d.r);

  label.attr('y', (d: any) => (d.y - v[1]) * k - d.r);
}

export function positioningHighlightCircles(v: any, circle: any, opening = false) {
  const k = config.WIDTH / v[2];

  const duration = opening ? 400 : 600;

  circle
    .transition()
    .duration(duration)
    .attr('transform', (d: any) => {
      const base_x = (d.x - v[0]) * k;
      const base_y = (d.y - v[1]) * k;
      const radius = d.r * (opening ? opening_proportion(d) : 1);

      //Based on pythagoras theorem where (translation ** 2 + translation ** 2 = radius ** 2)
      const translation = Math.sqrt(radius ** 2 / 2);

      return d.data.modelName == 'Result'
        ? `translate(${base_x + translation},${base_y - translation})`
        : `translate(${base_x + translation},${base_y - translation})`;
    });
}
