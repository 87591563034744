import Vue from 'vue';
import App from './App.vue';
import {
  XInstaller,
  InstallXOptions,
  XPlugin,
  namespacedWireCommit,
  namespacedWireDispatch,
  namespacedWireDispatchWithoutPayload,
  UrlParams,
  debounce,
  filter,
  SnippetConfig
} from '@empathyco/x-components';
import { adapter } from './adapter/adapter';
import { DefaultFacetsService, facetsXModule } from '@empathyco/x-components/facets';
import { Filter } from '@empathyco/x-types';
import { getURLParameter } from './scripts/utils';

Vue.config.productionTip = false;

declare module '@empathyco/x-components/facets' {
  interface FacetsState {
    activeFilter: Filter[];
  }
  interface FacetsMutations {
    addActiveFilter(filter: Filter): void;
    removeActiveFilter(indexFilter: number): void;
    removeAllActiveFilters(query?: string): void;
  }

  interface FacetsActions {
    deselectActiveFilter(filter: Filter): void;
    deselectAllActiveFilter(): void;
    selectActiveFilters(urlParams: UrlParams): void;
  }

  interface FacetsXEvents {
    UserDeselectedHolon: Filter;
    UserDeselectedAllHolons: void;
    CleanAllHolons: void;
    UserSetActiveFilter: Filter;
  }
}

const wireFacetsCommit = namespacedWireCommit('facets');
const wireFacetsSelectionDispatch = namespacedWireDispatch('facets');
const wireFacetsDispatch = namespacedWireDispatchWithoutPayload('facets');

export const installXOptions: InstallXOptions = {
  adapter,
  app: App,
  initialXModules: [facetsXModule],
  xModules: {
    facets: {
      wiring: {
        UserSetActiveFilter: {
          addActiveFilter: wireFacetsCommit('addActiveFilter')
        },
        UserDeselectedHolon: {
          removeActiveFilter: wireFacetsSelectionDispatch('deselectActiveFilter')
        },
        UserDeselectedAllHolons: {
          removeAllActiveFilters: wireFacetsDispatch('deselectAllActiveFilter')
        },
        CleanAllHolons: {
          removeAllActiveFilters: wireFacetsDispatch('deselectAllActiveFilter')
        },
        ParamsLoadedFromUrl: {
          setActiveFiltersFromUrl: debounce(wireFacetsSelectionDispatch('selectActiveFilters'), 500)
        },
        UserClearedQuery: {
          removeAllActiveFilters: filter(
            wireFacetsCommit('removeAllActiveFilters'),
            ({ eventPayload: query }) => {
              return query === '';
            }
          )
        }
      }
    }
  },
  __PRIVATE__xModules: {
    search: {
      storeModule: {
        actions: {
          fetchSearchResponse(_context, request) {
            if (request) {
              request = {
                ...request,
                extraParams: {
                  instance: 'empathy',
                  env: 'staging',
                  scope: 'desktop',
                  lang: 'en',
                  currency: 'EUR',
                  consent: true,
                  strictness: _strictness
                }
              };
              return XPlugin.adapter.search(request);
            } else {
              return {
                banners: [],
                facets: [],
                partialResults: [],
                promoteds: [],
                queryTagging: {
                  params: {},
                  url: ''
                },
                redirections: [],
                results: [],
                spellcheck: '',
                totalResults: 0
              };
            }
          }
        }
      }
    },
    facets: {
      storeModule: {
        state: {
          activeFilter: []
        },
        actions: {
          selectActiveFilters({ state, getters, commit }, { activeFilters }) {
            commit('removeAllActiveFilters');
            if (activeFilters) {
              const active = (activeFilters as string).split('-');
              active.forEach(activeFilter => {
                const filterSelected = getters.selectedFilters.find(
                  filter => filter.id === activeFilter
                );
                if (filterSelected) {
                  commit('addActiveFilter', filterSelected);
                }
              });
            }
          },
          deselectActiveFilter({ state, commit }, filter) {
            const indexFilter = state.activeFilter.indexOf(filter);
            if (indexFilter < state.activeFilter.length - 1) {
              for (var i = indexFilter + 1; i < state.activeFilter.length; i++) {
                const activeFilter = state.activeFilter[i];
                DefaultFacetsService.instance.deselect(activeFilter);
              }
              commit('removeActiveFilter', indexFilter + 1);
            } else {
              const activeFilter = state.activeFilter[indexFilter];
              DefaultFacetsService.instance.deselect(activeFilter);
              commit('removeActiveFilter', indexFilter);
            }
          },
          deselectAllActiveFilter({ state, getters, commit }) {
            if (state.activeFilter.length != getters.selectedFilters.length) {
              state.activeFilter = getters.selectedFilters;
            }
            state.activeFilter.forEach(filter => {
              DefaultFacetsService.instance.deselect(filter);
            });
            commit('removeAllActiveFilters');
          }
        },
        mutations: {
          addActiveFilter(state, filter) {
            state.activeFilter.push(filter);
          },
          removeAllActiveFilters(state) {
            state.activeFilter = [];
          },
          removeActiveFilter(state, indexFilter) {
            if (indexFilter == state.activeFilter.length - 1) {
              state.activeFilter.pop();
            } else {
              state.activeFilter.splice(indexFilter, state.activeFilter.length - 1);
            }
          }
        }
      }
    }
  }
};

const _strictness = getURLParameter('strictness') || 1;

new XInstaller(installXOptions).init({
  instance: 'empathy',
  env: 'staging',
  scope: 'desktop',
  lang: 'en',
  currency: 'EUR',
  consent: true,
  strictness: _strictness
} as SnippetConfig);
